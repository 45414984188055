import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
} from "../localStorage/localStorageService";

export const storePairService = (dataAPI) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/storePairService?company_uid=${CompanyId}&business_uid=${BusinessId}`,

    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
