import { Button, CircularProgress } from "@mui/material";
import React from "react";

function ButtonCustom({ props }) {
  let {
    text,
    onClick,
    isClickable,
    variant,
    color,
    icon,
    type,
    fullSize,
    size,
  } = props;
  return (
    <Button
      startIcon={
        isClickable ? (
          <CircularProgress color="white" size={20} />
        ) : (
          <> {icon ? icon : null}</>
        )
      }
      type={type}
      variant={variant}
      color={color}
      onClick={type ? null : onClick}
      disabled={isClickable}
      fullWidth={fullSize ? true : false}
      size={size}
    >
      {text}
    </Button>
  );
}

export default ButtonCustom;
