import { Box, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CompanyId } from "../../service/localStorage/localStorageService";
import { t } from "i18next";
import { getAllCompanyAPI } from "../../service/company/companyAPI";
import { SetCookie } from "../../service/cookies/setCookies";

function CompanyChoosing({ props }) {
  let { menuActive } = props;
  const [companyValue, setCompanyValue] = useState(null);
  const [companyAll, setCompanyAll] = useState([]);
  const fetchDataCompany = async () => {
    let dataAPI = await getAllCompanyAPI();
    setCompanyValue(CompanyId);
    setCompanyAll(dataAPI);
  };

  const onCompanyAll = async () => {
    window.location.replace("/company");
  };
  const onCompanyCreate = async () => {
    window.location.replace("/company/create");
  };
  const onCompanyChoose = async (companyValue) => {
    let { company } = companyValue;
    SetCookie(
      "company_name",
      company.company_lang.name,
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    SetCookie(
      "company_uid",
      company.company_uid,
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    window.location.replace(`/company/${company.company_uid}/business`);
  };
  useEffect(() => {
    fetchDataCompany();
  }, []);
  return (
    <Box sx={{ display: menuActive ? "none" : "inline" }}>
      {/* <Typography
        sx={(theme) => ({
          color: theme.palette.white.main,
          display: menuActive ? "none" : "flex",
          my: 1,
          ml: 1,
          fontFamily: "Kanit",
          fontSize: 12,
          opacity: 0.5,
        })}
        className="smoothTransition"
        align={menuActive && window.innerWidth > 600 ? "center" : "flex-start"}
      >
        {`${textId}`}
      </Typography> */}
      <Box
        sx={(theme) => ({
          boxSizing: "border-box",
          gap: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        })}
      >
        {/* <Typography sx={{ fontSize: 16 }}>Company</Typography> */}
        <Select
          value={companyValue}
          fullWidth
          size="small"
          sx={{ borderRadius: 2 }}
          align="center"
        >
          {/* <MenuItem value={1}>{CompanyName}</MenuItem> */}
          <MenuItem value={2} onClick={() => onCompanyAll()}>
            {t("navbar_see_all_company")}
          </MenuItem>
          <MenuItem value={3} onClick={() => onCompanyCreate()}>
            {t("navbar_add_company")}
          </MenuItem>
          <hr />
          <Typography
            sx={(theme) => ({
              fontSize: 12,
              ml: 2,
              color: theme.palette.primaryText.main + "80",
            })}
          >
            {t("navbar_all_company")}
          </Typography>
          {companyAll.map((company) => (
            <MenuItem
              value={company.company.company_uid}
              onClick={() => onCompanyChoose(company)}
            >
              {company.company.company_lang.name}
            </MenuItem>
          ))}
          {/* ... */}
        </Select>
      </Box>
    </Box>
  );
}

export default CompanyChoosing;
