import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getCompanyAPI,
  updateCompanyAPI,
} from "../../service/company/companyAPI";
// import { successSweetAlert } from "../../components/ui/notification/swalNotification";
import { Loading } from "../../components/ui/loading/loading";
import { LanguageForm } from "../../service/localStorage/localStorageService";
import { uploadImage } from "../../service/upload/uploadImage";
import NavbarCompany from "./navbarCompany";
import { useSchema } from "../../components/hooks/use-schema";
import { companySchema } from "../../components/validations/companySchema";
import CompanyForm from "./companyForm";
import { useToast } from "../../components/hooks/use-toast";
import { useNavigate } from "react-router-dom";

function CompanyDetail() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
  } = useForm(useSchema(companySchema));
  const { successToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [image, setImage] = useState();
  const [address, setAddress] = useState({});
  const [isClickable, setIsClickable] = useState(false);
  const [country, setCountry] = useState("");
  const [anchorMap, setAnchorMap] = useState([
    18.788852690765257, 98.98393206985963,
  ]);
  const navigate = useNavigate();
  const onConfirm = async (register) => {
    setIsClickable(true);
    setTimeout(() => {
      setIsClickable(false);
    }, 3000);
    let newImage = "";
    if (typeof image === "object") {
      newImage = await uploadImage(image);
    } else {
      newImage = image;
    }
    let dataAPI = {
      company_uid: data.company.company_uid,
      logo: newImage,
      language_code: LanguageForm,
      company_lang: {
        name: register.companyName,
      },
      company_address: {
        address_line_1: register.addressOne ? register.addressOne : null,
        address_line_2: register.addressTwo ? register.addressTwo : null,
        state: register.state,
        city: register.city,
        country: register.country,
        postcode_no: register.postalCode,
        lat: String(anchorMap[0]),
        long: String(anchorMap[1]),
      },
    };
    let statusAPI = await updateCompanyAPI(dataAPI);
    if (statusAPI.message === "OK") {
      successToast({ title: t("notification_edit_info_successful") });
      navigate("/company");
    }
  };
  const { t } = useTranslation();

  const fetchData = async () => {
    let paramsId = window.location.pathname.split("/").at(-1);

    let dataAPI = await getCompanyAPI(paramsId);
    let { logo, company_lang, company_address } = dataAPI.company;
    let { name } = company_lang;
    let { city, country, state, postcode_no, address_line_1, address_line_2 } =
      company_address;
    setValue("companyName", name);
    setValue("country", country);
    setValue("city", city);
    setValue("state", state);
    setValue("postalCode", postcode_no);
    setValue("addressOne", address_line_1);
    setValue("addressTwo", address_line_2);
    setCountry(country);
    setImage(logo);
    setData(dataAPI);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <Box>
          <NavbarCompany
            props={{
              textBack: t("company_back_to_company_list"),
              onReDirectUrl: "/company",
              textOnDirect: t(
                "notification_confirm_cancel_create_or_edit_information"
              ),
            }}
          />
          <CompanyForm
            props={{
              title: t("company"),
              handleSubmit,
              onConfirm,
              setImage,
              image,
              register,
              errors,
              setAnchorMap,
              country,
              setCountry,
              isClickable,
              anchorMap,
              control,
              setValue,
              clearErrors,
              address,
              setAddress,
            }}
          />
        </Box>
      )}
    </>
  );
}

export default CompanyDetail;
