export function clearAllCookies(domain) {
  const cookies = document.cookie.split("; ");
  const pastDate = new Date(0);

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    document.cookie = `${
      cookie[0]
    }=;expires=${pastDate.toUTCString()};domain=${domain};path=/`;
  }
}

// Example usage
// clearAllCookies("domain");
