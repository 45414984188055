import {
  Box,
  Typography,
  Popover,
  Avatar,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import thLanguage from "../../../assets/svg/thLanguage.svg";
import enLanguage from "../../../assets/svg/enLanguage.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import Swal from "sweetalert2";
import PersonIcon from "@mui/icons-material/Person";
import i18next, { t } from "i18next";
// import TypographyTitleSidebar from "../typographyTitleSidebar";
function SelectLanguage({ props }) {
  const [languageSystem, setLanguageSystem] = useState("");
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const openProfile = Boolean(anchorElProfile);
  const idProfile = openProfile ? "simple-popover" : undefined;

  const onLanguageSystem = (e) => {
    if (e !== localStorage.getItem("languageSystem")) {
      i18next.changeLanguage(e);
      setLanguageSystem(e);
      localStorage.setItem("language_system", e);
      localStorage.setItem("language_form", e);
      window.location.reload();
    } else {
      // setAnchorElLanguage(null);
    }
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const onLogout = () => {
    Swal.fire({
      title: t("notification_confirm_logout"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("button_yes_or_confirm"),
      cancelButtonText: t("button_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        window.location.replace(
          `${process.env.REACT_APP_SMART_PLATFORM_DOMAIN_URL}/login`
        );
      }
    });
  };

  useEffect(() => {
    const languageSystemCheck = localStorage.getItem("language_system");
    setLanguageSystem(languageSystemCheck);
  }, []);
  return (
    <>
      <Box
        sx={{
          gap: 1,
          alignItems: "center",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        {/* <TypographyTitleSidebar
          props={{
            text: t("side_bar_languages_select"),
          }}
        /> */}

        <Select
          value={languageSystem}
          onChange={(e) => onLanguageSystem(e.target.value)}
          // size="small"
          sx={(theme) => ({
            color: "white",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.bgSizeBarRight.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.bgSizeBarRight.main,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.bgSizeBarRight.main,
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          })}
          inputProps={{
            IconComponent: () => null,
            sx: { paddingRight: "10px !important" },
          }}
        >
          <MenuItem value="th">
            <Box sx={(theme) => ({ display: "flex", alignItems: "center" })}>
              <img
                src={thLanguage}
                alt="thLanguage"
                style={{ borderRadius: "4px", width: "32px" }}
              />
            </Box>
          </MenuItem>
          <MenuItem value="en">
            <Box sx={(theme) => ({ display: "flex", alignItems: "center" })}>
              <img
                src={enLanguage}
                alt="enLanguage"
                style={{ borderRadius: "4px", width: "32px" }}
              />
            </Box>
          </MenuItem>
        </Select>
        <Box
          sx={(theme) => ({
            height: "100%",
            width: "2px",
            backgroundColor: "#cccccc50",
            borderRadius: "50%",
          })}
        />
        <Box
          onClick={(event) => setAnchorElProfile(event.currentTarget)}
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            p: 1,
            borderRadius: 4,
            boxSizing: "border-box",
            // backgroundColor: "#f3f3f8",
            height: "100%",
            ":hover": { cursor: "pointer", opacity: 0.8 },
          })}
        >
          <Avatar
            alt="EmoProfile"
            src="https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg"
          />
          {/* <Box>
            <Typography
              sx={(theme) => ({
                fontSize: 12,
                fontFamily: "Kanit",
                color: theme.palette.primary.main,
              })}
            >
              STAFF Account
            </Typography>
          </Box> */}
        </Box>
      </Box>
      <Popover
        id={idProfile}
        open={openProfile}
        anchorEl={anchorElProfile}
        onClose={handleCloseProfile}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={1}>
          <Typography sx={{ fontSize: 12, color: "#aaa" }}>
            Welcome User
          </Typography>
          <Button
            sx={{
              display: "flex",
              gap: 1,
              width: "100%",
              borderRadius: 2,
            }}
            color="secondary"
            disabled
          >
            <PersonIcon sx={(theme) => ({})} />
            <Typography>Profile</Typography>
          </Button>
          <hr />
          <Typography sx={{ fontSize: 12, color: "#aaa" }}>Setting</Typography>
          <Button
            sx={{
              display: "flex",
              gap: 1,
              width: "100%",
              borderRadius: 2,
            }}
            color="secondary"
            onClick={() => onLogout()}
          >
            <LogoutIcon sx={(theme) => ({})} />
            <Typography>Back to platform</Typography>
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default SelectLanguage;
