import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  // Divider,
  IconButton,
  Paper,
  Tab,
  Typography,
  // Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SmartPos from "./smartPos/smartPos";
import MemberShip from "./membership/membership";
// import TvIcon from "@mui/icons-material/Tv";
// import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { getAllPairService } from "../../service/pair-service/getAllPairService";
import { SetCookie } from "../../service/cookies/setCookies";
import { Loading } from "../../components/ui/loading/loading";
import { getMembershipRegistrationAPI } from "../../service/service/membership/getMembershipRegistration";
import { useToast } from "../../components/hooks/use-toast";
import moment from "moment";
import {
  BusinessId,
  CompanyId,
} from "../../service/localStorage/localStorageService";
import Swal from "sweetalert2";
import { RegisterMemberShipService } from "../../service/service/membership/registerMemberShipService";
import { getSPOSRegistrationAPI } from "../../service/service/spos/getSPOSRegistration";
import { t } from "i18next";
import { storePairService } from "../../service/pair-service/storePairService";
import { RegisterSPOSService } from "../../service/service/spos/registerSPOSService";
import BreadcrumbsTitle from "../../components/ui/breadcrumbs/breadcrumbsTitle";
import packageService from "./packageService.json";
import { Close } from "@mui/icons-material";
import ConfirmButton from "../../components/ui/button/confirmButton";
import { useForm } from "react-hook-form";
import SelectComponent from "../../components/ui/select/selectComponent";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
function ServiceBusiness() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = useForm();
  const { successToast } = useToast();
  const [valueStep, setValueStep] = useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const [dialogService, setDialogService] = useState({
    status: false,
    type: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataMembership, setDataMembership] = useState([]);
  const [dataSPOS, setDataSPOS] = useState([]);
  const [statusPair, setStatusPair] = useState(null);

  const handleChange = (event, newValue) => {
    setValueStep(newValue);
  };
  const fetchData = async () => {
    setIsLoading(true);
    let dataAPI = await getAllPairService();
    if (dataAPI.data.length > 0) {
      dataAPI.data.forEach((item) => {
        setStatusPair(true);
      });
      SetCookie(
        "status_pair",
        "sposXmembership",
        7,
        process.env.REACT_APP_COOKIES_DOMAIN_URL
      );
    }
    await fetchDataSPOS();
    await fetchDataMembership();
    await setIsSubmitting(false);
    setIsLoading(false);
  };

  const onPairService = async () => {
    let dataAPI = {
      service_code_a: "membership",
      service_code_b: "spos",
      config: {},
      status: true,
    };
    Swal.fire({
      title: t("notification_confirm_pair_service"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("button_yes_or_confirm"),
      cancelButtonText: t("button_cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let statusAPI = await storePairService(dataAPI);
        if (statusAPI.message === "OK") {
          successToast({
            title: t("notification_success_pair_service"),
          });
          fetchData();
        }
      }
    });
  };

  const onDirectToServiceMembership = async () => {
    SetCookie(
      "service_code_membership",
      "membership",
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    window.open(process.env.REACT_APP_MEMBERSHIP_DOMAIN_URL + `/customers`);
  };

  const fetchDataMembership = async () => {
    let dataAPI = await getMembershipRegistrationAPI();
    if (dataAPI.data) {
      let { updated_at, registration_status } = dataAPI.data;
      setDataMembership([
        {
          id: 1,
          name: "SMART MEMBERSHIP",
          date: moment(updated_at).format("YYYY-MM-DD"),
          status: registration_status,
          data: dataAPI.data,
        },
      ]);
    }
  };

  const onRegisterServiceMembership = async (packageUID) => {
    await setIsSubmitting(true);
    let dataAPI = {
      business_uid: BusinessId,
      package_uid: packageUID,
    };
    Swal.fire({
      title: t("notification_confirm_register_service"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("button_yes_or_confirm"),
      cancelButtonText: t("button_cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let statusAPI = await RegisterMemberShipService(dataAPI);
        if (statusAPI.message === "OK") {
          successToast({
            title: t("notification_success_registration_service"),
          });
          fetchData();
        }
      }
    });
  };

  const onDirectToServiceSPOS = async () => {
    SetCookie(
      "service_code",
      "spos",
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    window.open(process.env.REACT_APP_SPOS_PLATFORM_DOMAIN_URL + `/branch`);
  };
  const fetchDataSPOS = async () => {
    let dataAPI = await getSPOSRegistrationAPI();
    if (dataAPI.data) {
      let { updated_at, registration_status } = dataAPI.data;
      setDataSPOS([
        {
          id: 1,
          name: "SPOS SERVICE",
          date: moment(updated_at).format("YYYY-MM-DD"),
          status: registration_status,
          data: dataAPI.data,
        },
      ]);
    }
  };

  const onRegisterServiceSPOS = async (packageUID) => {
    await setIsSubmitting(true);
    let dataAPI = {
      business_uid: BusinessId,
      package_uid: packageUID,
    };
    Swal.fire({
      title: t("notification_confirm_register_service"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("button_yes_or_confirm"),
      cancelButtonText: t("button_cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let statusAPI = await RegisterSPOSService(dataAPI);
        if (statusAPI.message === "OK") {
          successToast({
            title: t("notification_success_registration_service"),
          });
          fetchData();
        }
      }
    });
  };
  const onCreateZone = async (register) => {
    let packageUID = packageService.find(
      (item) => item.id === register.package
    ).uid;
    if (dialogService.type === "servicePOS") {
      setDialogService({
        status: false,
        type: null,
      });
      onRegisterServiceSPOS(packageUID);
    }
    if (dialogService.type === "membership") {
      setDialogService({
        status: false,
        type: null,
      });
      onRegisterServiceMembership(packageUID);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("business"),
          dataLink: [
            { text: t("company"), link: "/company" },
            { text: t("business"), link: `/company/${CompanyId}/business/` },
            { text: t("service"), link: null },
          ],
        }}
      />

      <Box sx={{ p: 4, boxSizing: "border-box" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.primaryText.main,
              })}
              variant="h2"
            >
              {t("global_service")}
            </Typography>
            <Typography
              sx={(theme) => ({
                color: theme.palette.secondaryText.main,
              })}
              variant="h4"
            >
              {t("global_service_title_info")}
            </Typography>
          </Box>
          {/* <Button
                startIcon={<Add />}
                variant="contained"
                sx={{ borderRadius: 2 }}
              onClick={() => navigate("create")}
              >
                Register service
              </Button> */}
        </Box>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <TabContext value={valueStep}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs"
                  variant="scrollable"
                >
                  {[
                    t("global_smart_pos_service"),
                    t("global_smart_membership_service"),
                    // t("table_manage_zone"),
                  ].map((item, index) => (
                    <Tab label={item} key={index} value={String(index + 1)} />
                  ))}
                </TabList>
              </Box>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <TabPanel value="1" sx={{ p: 0 }}>
                    <Paper
                      sx={{
                        flexGrow: 1,
                        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, .03)",
                        border: "1px solid #F1F1F1",
                        backgroundColor: "#fff",
                      }}
                    >
                      <BoxGapInput>
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <>
                            <SmartPos
                              props={{
                                data: dataSPOS,
                                statusPair,
                                onDirectToService: onDirectToServiceSPOS,
                                onRegisterService: () =>
                                  setDialogService({
                                    status: true,
                                    type: "servicePOS",
                                  }),
                                onPairService: onPairService,
                              }}
                            />
                          </>
                        )}
                      </BoxGapInput>
                    </Paper>
                  </TabPanel>
                  <TabPanel value="2" sx={{ p: 0 }}>
                    <Paper
                      sx={{
                        flexGrow: 1,
                        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, .03)",
                        border: "1px solid #F1F1F1",
                        backgroundColor: "#fff",
                      }}
                    >
                      <BoxGapInput>
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <>
                            <MemberShip
                              props={{
                                data: dataMembership,
                                statusPair,
                                onDirectToService: onDirectToServiceMembership,
                                onRegisterService: () =>
                                  setDialogService({
                                    status: true,
                                    type: "membership",
                                  }),
                                onPairService: onPairService,
                              }}
                            />
                          </>
                        )}
                      </BoxGapInput>
                    </Paper>
                  </TabPanel>
                </>
              )}
            </TabContext>
          </>
        )}
      </Box>

      <Dialog open={dialogService.status} maxWidth="sm" fullWidth>
        <DialogTitle>{t("Package Service")}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setDialogService(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <form onSubmit={handleSubmit(onCreateZone)}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <SelectComponent
                title={t("Service package")}
                register={register}
                name="package"
                errors={errors}
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
                dataEmpty={t("Select Service package")}
                optionsData={packageService}
              />
              <ConfirmButton disabled={isSubmitting} />
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ServiceBusiness;
