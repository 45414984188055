import React from "react";
import TextField from "@mui/material/TextField";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { TypographyError } from "../typography/typography-error";
// import TitleTextField from "../title/titleTextField";

const TextfieldComponent = ({
  title,
  name,
  register,
  placeholder,
  errors,
  iconEnd,
  iconEndOnclick,
  disabled,
  multiline,
  rows,
  type,
  helperText,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* <TitleTextField props={{ text: title }} /> */}
      <TextField
        {...register(name)}
        name={name}
        type={type ? type : "text"}
        placeholder={placeholder ? placeholder : null}
        error={errors[name]}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label={title}
        size="small"
        InputProps={{
          endAdornment: (
            <>
              {errors[name] ? // /> //   sx={(theme) => ({ color: theme.palette.primary.main })} // <ErrorIcon
              null : (
                <>
                  {iconEndOnclick ? (
                    <IconButton onClick={() => iconEndOnclick()}>
                      <InputAdornment position="end">{iconEnd}</InputAdornment>
                    </IconButton>
                  ) : (
                    <InputAdornment position="end">{iconEnd}</InputAdornment>
                  )}
                </>
              )}
            </>
          ),
        }}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
      />
      <TypographyError textError={errors[name]} />
    </Box>
  );
};

export default TextfieldComponent;
