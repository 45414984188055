import heic2any from "heic2any";

export const convertToPNG = async (heicFile) => {
  try {
    const pngBlob = await heic2any({
      blob: heicFile,
      toType: "image/png",
      quality: 1,
    })
    return pngBlob;
  } catch (error) {
    throw new Error("Failed to convert HEIC to PNG");
  }
};
