import React from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import StoreIcon from "@mui/icons-material/Store";
import Business from "../pages/business/business";
import ClientUser from "../pages/clientUser/clientUser";
import {
  BusinessId,
  CompanyId,
} from "../service/localStorage/localStorageService";
import { t } from "i18next";
export const ListItemMenu = () => {
  const listItem = [];
  // ==== Business ====

  if (BusinessId === null) {
    listItem.push({
      name: t("side_bar_business"),
      exact: true,
      iconClassName: <StoreIcon sx={{ fontSize: 20 }} />,
      role: "company",
      to: `company/${CompanyId}/business`,
      pathName: "business",
      component: <Business />,
    });
  }
  listItem.push({
    name: t("side_bar_client_user"),
    exact: true,
    iconClassName: <GroupsIcon sx={{ fontSize: 20 }} />,
    role: "company",
    to: `company/${CompanyId}/clientUser`,
    pathName: "clientUser",
    component: <ClientUser />,
  });
  return listItem;
};
