import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { createCompanyAPI } from "../../service/company/companyAPI";
import { successSweetAlert } from "../../components/ui/notification/swalNotification";
import { uploadImage } from "../../service/upload/uploadImage";
import { removeCookie } from "../../service/cookies/removeCookies";
import { companySchema } from "../../components/validations/companySchema";
import { t } from "i18next";
import NavbarCompany from "./navbarCompany";
import { useSchema } from "../../components/hooks/use-schema";
import CompanyForm from "./companyForm";

function CompanyCreate() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm(useSchema(companySchema));
  const [isClickable, setIsClickable] = useState(false);
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState({});
  const [anchorMap, setAnchorMap] = useState([
    18.788852690765257, 98.98393206985963,
  ]);
  const [image, setImage] = useState(null);
  const onConfirm = async (data) => {
    setIsClickable(true);
    setTimeout(() => {
      setIsClickable(false);
    }, 3000);
    let {
      country,
      state,
      city,
      postalCode,
      companyName,
      addressOne,
      addressTwo,
    } = data;
    let newImage = "";
    if (image) {
      newImage = await uploadImage(image);
    }
    let dataAPI = {
      logo: newImage,
      // language_code: LanguageForm,
      company_lang: {
        name: companyName,
      },
      company_address: {
        address_line_1: addressOne ? addressOne : null,
        address_line_2: addressTwo ? addressTwo : null,
        state: state,
        city: city,
        country: country,
        postcode_no: postalCode,
        lat: String(anchorMap[0]),
        long: String(anchorMap[1]),
      },
    };
    let statusAPI = await createCompanyAPI(dataAPI);
    if (statusAPI.message === "OK") {
      successSweetAlert({
        text: t("notification_create_info_successful"),
      });
      window.location.replace("/company");
    }
  };
  useEffect(() => {
    removeCookie("business_uid", process.env.REACT_APP_COOKIES_DOMAIN_URL);
    removeCookie("company_uid", process.env.REACT_APP_COOKIES_DOMAIN_URL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <NavbarCompany
        props={{
          textBack: t("company_back_to_company_list"),
          onReDirectUrl: "/company",
          textOnDirect: t(
            "notification_confirm_cancel_create_or_edit_information"
          ),
        }}
      />
      <CompanyForm
        props={{
          title: t("company"),
          handleSubmit,
          onConfirm,
          setImage,
          image,
          register,
          errors,
          setAnchorMap,
          country,
          setCountry,
          isClickable,
          anchorMap,
          control,
          setValue,
          clearErrors,
          address,
          setAddress,
        }}
      />
    </Box>
  );
}

export default CompanyCreate;
