import auth from "./auth.json";
import register from "./register.json";
import thLocals from "./th-locals.json";
import validateFormat from "./validate-format.json";
import company from "./company.json";
import address from "./address.json";
import business from "./business.json";
import clientUser from "./clientUser.json";
import button from "./button.json";
import sideBar from "./sidebar.json";
import global from "./global.json";
import notification from "./notification.json";
import navbar from "./navbar.json";
export const th = {
  ...sideBar,
  ...auth,
  ...thLocals,
  ...register,
  ...validateFormat,
  ...company,
  ...address,
  ...business,
  ...clientUser,
  ...button,
  ...global,
  ...notification,
  ...navbar,
};
