import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getBusinessAPI,
  updateBusinessAPI,
} from "../../service/business/businessAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/ui/loading/loading";
import {
  CompanyId,
  LanguageForm,
} from "../../service/localStorage/localStorageService";
import BreadcrumbsTitle from "../../components/ui/breadcrumbs/breadcrumbsTitle";
import { uploadImage } from "../../service/upload/uploadImage";
import { businessSchema } from "../../components/validations/businessSchema";
import { useToast } from "../../components/hooks/use-toast";
import { t } from "i18next";
import { useSchema } from "../../components/hooks/use-schema";
import BusinessForm from "./businessForm";
function BusinessDetail() {
  const {
    register,
    control,
    setValue,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm(useSchema(businessSchema));
  const [isLoading, setIsLoading] = useState(true);
  const [isClickable, setIsClickable] = useState(false);
  const [image, setImage] = useState();
  const [country, setCountry] = useState("");
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [anchorMap, setAnchorMap] = useState(null);
  const location = useLocation();
  const paramsId = location.pathname.split("/").at(-1);
  const { successToast } = useToast();
  const onConfirm = async (register) => {
    setIsClickable(true);
    setTimeout(() => {
      setIsClickable(false);
    }, 3000);
    let newImage = "";
    if (typeof image === "object") {
      newImage = await uploadImage(image);
    } else {
      newImage = image;
    }
    let {
      taxId,
      businessName,
      juristicName,
      businessType,
      country,
      city,
      state,
      postalCode,
      addressOne,
      addressTwo,
      vatStatus,
    } = register;
    let dataAPI = {
      business_uid: data.business_uid,
      tax_id: taxId,
      vat_status: vatStatus === t("business_vat_include") ? true : false,
      logo: newImage,
      language_code: LanguageForm,
      business_lang: {
        business_name: businessName,
        juristic_name: juristicName,
        business_type: businessType,
      },
      business_address: {
        address_line_1: addressOne ? addressOne : null,
        address_line_2: addressTwo ? addressTwo : null,
        state,
        city,
        country,
        postcode_no: postalCode,
        lat: String(anchorMap[0]),
        long: String(anchorMap[1]),
      },
    };
    let statusAPI = await updateBusinessAPI(dataAPI);
    if (statusAPI.message === "OK") {
      navigate(`/company/${CompanyId}/business`);
      successToast({ title: t("notification_edit_info_successful") });
    }
  };
  const fetchData = async () => {
    let dataAPI = await getBusinessAPI(paramsId);
    let { business_lang, business_address, tax_id, vat_status, logo } = dataAPI;
    let { business_name, business_type, juristic_name } = business_lang;
    let {
      country,
      city,
      state,
      lat,
      long,
      postcode_no,
      address_line_1,
      address_line_2,
    } = business_address;
    setData(dataAPI);
    setImage(logo);
    setValue(
      "vatStatus",
      vat_status ? t("business_vat_include") : t("business_vat_exclude")
    );
    setValue("businessName", business_name);
    setValue("businessType", business_type);
    setValue("juristicName", juristic_name);
    setValue("taxId", tax_id);
    setValue("city", city);
    setValue("state", state);
    setValue("country", country);
    setValue("postalCode", postcode_no);
    setValue("addressOne", address_line_1);
    setValue("addressTwo", address_line_2);
    setAnchorMap([lat, long]);
    setCountry(country);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("business_info"),
          dataLink: [
            { text: t("business"), link: `/company/${CompanyId}/business` },
            { text: t("business_info"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ p: 2, boxSizing: "border-box" }}>
            <BusinessForm
              props={{
                title: t("business_info"),
                handleSubmit,
                onConfirm,
                setImage,
                image,
                register,
                errors,
                setAnchorMap,
                country,
                setCountry,
                isClickable,
                anchorMap,
                control,
                setValue,
                clearErrors,
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default BusinessDetail;
