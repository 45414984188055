import { Box } from "@mui/material";
import React from "react";

export function Loading() {
  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        p: 4,
      }}
    >
      <Box sx={{ position: "relative", mr: 6 }}>
        <div className="circleLoadingOne"></div>
        <div className="circleLoadingTwo"></div>
        <div className="circleLoadingThree"></div>
        <div className="circleLoadingFour"></div>
      </Box>
    </Box>
  );
}
