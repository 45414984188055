import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  TextField,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { registerAPI } from "../../service/auth/register";
import ImageLoginPage from "../../assets/images/ImageLoginPage.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LanguageForm } from "../../service/localStorage/localStorageService";
import { TypographyHeadPage } from "../../components/ui/typography/typography-head-page";
import { TypographyError } from "../../components/ui/typography/typography-error";
import { userRegisterSchema } from "../../components/validations/userRegisterSchema";
import { useToast } from "../../components/hooks/use-toast";
import RegisterApproval from "./registerApproval";
import ButtonCustom from "../../components/ui/button";
import { useSchema } from "../../components/hooks/use-schema";

function Register() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(useSchema(userRegisterSchema));
  const { successToast } = useToast();
  const [isClickable, setIsClickable] = useState(false);
  const [registerDone, setRegisterDone] = useState(false);
  const [password, setPassword] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [anchorPassword, setAnchorPassword] = useState(null);
  const openAnchorPassword = Boolean(anchorPassword);

  const onClickShowPassword = (name) => {
    setPassword({
      ...password,
      [name]: !password[name],
    });
  };
  const { t } = useTranslation();
  const onRegister = async (data) => {
    let { username, password, email, tel, firstname, lastname } = data;
    setIsClickable(true);
    setTimeout(() => {
      setIsClickable(false);
    }, 3000);
    let dataAPI = {
      username,
      password,
      email,
      tel,
      client_user_lang: {
        firstname,
        lastname,
        language_code: LanguageForm,
      },
    };
    let statusAPI = await registerAPI(dataAPI);
    if (statusAPI.message === "OK") {
      successToast({ title: "สร้างบัญชีสำเร็จ" });
      setRegisterDone(true);
    }
  };
  const onLogin = () => {
    window.location.replace("/login");
  };

  useEffect(() => {}, []);
  return (
    <Box
      sx={(theme) => ({
        color: theme.palette.primaryText.main,
        height: "100vh",
        display: "grid",
        placeItems: "center",
      })}
      className="smoothTransition"
    >
      {registerDone ? (
        <>
          <RegisterApproval buttonText={"Back to Sign In"} linkUrl={"/login"} />
        </>
      ) : (
        <>
          <Grid
            container
            justifyContent="center"
            className="smoothTransition"
            sx={{ margin: "auto" }}
            xs={8}
            sm={8}
            md={8}
            lg={8}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={5}
              sx={(theme) => ({
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 5,
                borderTopLeftRadius: 15,
                borderBottomLeftRadius: 15,
                [theme.breakpoints.up("xs")]: {
                  display: "none",
                },
                [theme.breakpoints.up("lg")]: {
                  display: "flex",
                  backgroundColor: theme.palette.primary.main,
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.up("xs")]: {
                    width: "30%",
                  },
                  [theme.breakpoints.up("sm")]: {
                    width: "50%",
                  },
                })}
              >
                <img src={ImageLoginPage} alt="ImageLoginPage" width="100%" />
              </Box>
              <Box
                sx={{
                  textDecoration: "underline",
                  position: "absolute",
                  display: "flex",
                  color: "#f1f1f1",
                  bottom: 20,
                  ":hover": {
                    cursor: "pointer",
                    opacity: 0.8,
                  },
                }}
                onClick={() => onLogin()}
              >
                <ArrowBackIosIcon />
                <Typography>{t("register_back_to_login")}</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={5}
              sx={(theme) => ({
                // borderTopRightRadius: 15,
                // borderBottomRightRadius: 15,
                borderRadius: 2,
                boxShadow: 4,
                // borderRadius: 2,
                [theme.breakpoints.up("xs")]: {
                  px: 4,
                  // boxShadow: 0,
                },
              })}
            >
              <Box mb={4}>
                <Grid
                  item
                  xs={12}
                  p={1}
                  my={2}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <TypographyHeadPage props={{ text: t("register_title") }} />
                </Grid>

                <form onSubmit={handleSubmit(onRegister)}>
                  <Grid
                    container
                    justifyContent={"flex-start"}
                    my={2}
                    spacing={2}
                  >
                    <Grid item xs={6}>
                      <TextField
                        {...register("firstname")}
                        label={t("register_first_name")}
                        name="firstname"
                        id="firstname"
                        type="text"
                        size="small"
                        error={Boolean(errors.firstname)}
                        fullWidth
                      />
                      <TypographyError textError={errors?.firstname} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        {...register("lastname")}
                        label={t("register_last_name")}
                        name="lastname"
                        id="lastname"
                        type="text"
                        size="small"
                        error={Boolean(errors.lastname)}
                        fullWidth
                      />
                      <TypographyError textError={errors?.lastname} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        {...register("email")}
                        fullWidth
                        label={t("register_email")}
                        name="email"
                        type="text"
                        id="email"
                        size="small"
                        error={Boolean(errors.email)}
                      />
                      <TypographyError textError={errors?.email} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...register("username")}
                        fullWidth
                        label={t("register_username")}
                        name="username"
                        type="text"
                        id="username"
                        size="small"
                        error={Boolean(errors.username)}
                      />
                      <TypographyError textError={errors?.username} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...register("tel")}
                        fullWidth
                        label={t("register_tel")}
                        name="tel"
                        type="text"
                        id="tel"
                        inputProps={{
                          inputMode: "numeric",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        size="small"
                        error={Boolean(errors.tel)}
                      />
                      <TypographyError textError={errors?.tel} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...register("password")}
                        type={password.showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                onClickShowPassword("showPassword")
                              }
                              edge="end"
                            >
                              {password.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                        label={"Password"}
                        name="password"
                        id="password"
                        size="small"
                        error={Boolean(errors.password)}
                        fullWidth
                      />
                      <TypographyError textError={errors?.password} />
                      <FormHelperText>
                        {t("suggestions.passwordHint")}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <TextField
                          {...register("confirmPassword")}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  onClickShowPassword("showPassword")
                                }
                                edge="end"
                              >
                                {password.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            ),
                          }}
                          type={
                            password.showConfirmPassword ? "text" : "password"
                          }
                          label="Confirm Password"
                          name="confirmPassword"
                          id="confirmPassword"
                          size="small"
                          error={Boolean(errors.confirmPassword)}
                        />
                      </Box>
                      <TypographyError textError={errors?.confirmPassword} />
                    </Grid>

                    <Grid item xs={12} my={2}>
                      <ButtonCustom
                        props={{
                          text: t("register_confirm"),
                          variant: "contained",
                          color: "primary",
                          type: "submit",
                          isClickable: isClickable,
                          fullSize: true,
                        }}
                      />
                      <Box
                        sx={(theme) => ({
                          textDecoration: "underline",
                          color: theme.palette.primaryText.main + "50",
                          mt: 2,
                          width: "100%",
                          [theme.breakpoints.up("lg")]: { display: "none" },
                          ":hover": { cursor: "pointer" },
                        })}
                        onClick={() => onLogin()}
                        align="center"
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          {t("register_back_to_login")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
          <Menu
            id="basic-menu"
            anchorEl={anchorPassword}
            open={openAnchorPassword}
            onClose={() => setAnchorPassword(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              sx: {
                borderRadius: 2,
                width: "20%",
                px: 2,
              },
            }}
          >
            <Typography>{t("suggestions.passwordHint")}</Typography>
          </Menu>
        </>
      )}
    </Box>
  );
}

export default Register;
