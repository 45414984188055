import { Box, Chip, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { BoxImage } from "../../components/ui/boxImage/boxImage";
import EditIcon from "@mui/icons-material/Edit";
import { t } from "i18next";
import { East } from "@mui/icons-material";
import moment from "moment";
function CardItem({ props }) {
  let {
    title,
    imgUrl,
    data,
    onClickDirect,
    onClickEdit,
    dataEdit,
    isLoading,
    onClickDirectText,
    pair,
  } = props;
  return (
    <Box
      sx={(theme) => ({
        boxSizing: "border-box",
        backgroundColor: "#ffffff",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        borderRadius: 2,
        position: "relative",
        zIndex: 98,
      })}
      className="transitionEaseInOut"
    >
      <Box
        sx={{
          boxSizing: "border-box",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            position: "relative",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <BoxImage
            image={imgUrl}
            isLoading={isLoading}
            height={"64px"}
            width={"64px"}
            type="table"
          />
          <IconButton
            sx={{ position: "absolute", right: 0, top: 0, zIndex: 9999 }}
            onClick={() => onClickEdit(dataEdit)}
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Box sx={{}}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              fontFamily: "Kanit",
              fontSize: 20,
              fontWeight: 500,
              gap: 1,
            }}
          >
            {title}
            {pair ? (
              <>
                <Chip label="Pair" />
              </>
            ) : (
              <></>
            )}
          </Typography>

          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 400,
              }}
            >
              Company ID :
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                textTransform: "capitalize",
              })}
            >
              {data.company.company_uid}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                color: theme.palette.primary.main + "50",
                fontWeight: 400,
              })}
            >
              Update at :
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                color: theme.palette.primary.main + "50",
                textTransform: "capitalize",
              })}
            >
              {moment(data.updated_at).format("DD/MM/YYYY")}
              {pair ? (
                <>
                  <Chip label="Pair" />
                </>
              ) : (
                <></>
              )}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            {/* <ButtonCustom
            props={{
              text: onClickDirectText ? onClickDirectText : t("global_setting"),
              variant: "contained",
              color: "primary",
              icon: <DirectionsIcon />,
              onClick: () => onClickDirect(data),
              isClickable: false,
              fullSize: true,
            }}
          /> */}

            {/* <Button
            startIcon={<EditIcon />}
            color="primary"
            sx={{
              width: "50%",
              boxShadow: 2,
              backgroundColor: "#fff",
            }}
            onClick={() => onClickEdit(dataEdit)}
          >
            {t("button_edit")}
          </Button> */}
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 1, width: "100%" }} />
      <Box
        sx={(theme) => ({
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          textTransform: "uppercase",
          color: theme.palette.primary.main,
          pb: 1,
          ":hover": { opacity: 0.5, cursor: "pointer" },
        })}
        onClick={() => onClickDirect(data)}
        align="center"
      >
        {/* <DirectionsIcon /> */}
        <Typography sx={{ fontSize: 14 }}>
          {onClickDirectText ? onClickDirectText : t("global_setting")}
        </Typography>
        <East color="primary" sx={{ fontSize: 16 }} />
      </Box>
    </Box>
  );
}
export default CardItem;
