import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { successSweetAlert } from "../../components/ui/notification/swalNotification";
import { useTranslation } from "react-i18next";
import {
  getClientUserAPI,
  updateClientUserAPI,
} from "../../service/clientUser/clientUserAPI";
import { Loading } from "../../components/ui/loading/loading";
import {
  CompanyId,
  LanguageForm,
} from "../../service/localStorage/localStorageService";
import BreadcrumbsTitle from "../../components/ui/breadcrumbs/breadcrumbsTitle";
import { useSchema } from "../../components/hooks/use-schema";
import { clientUserSchema } from "../../components/validations/clientUserSchema";
import ClientUserForm from "./clientUserForm";

function ClientUserDetail() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(useSchema(clientUserSchema({ detail: true })));
  const [isLoading, setIsLoading] = useState(true);
  const [isClickable, setIsClickable] = useState(false);
  const [clientUserId, setClientUserId] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const paramsId = location.pathname.split("/").at(-1);

  const onConfirm = async (dataSubmit) => {
    setIsClickable(true);
    setTimeout(() => {
      setIsClickable(false);
    }, 3000);
    let { email, tel, firstname, lastname } = dataSubmit;
    let dataAPI = {
      client_user_uid: clientUserId,
      email: email,
      tel: tel,
      client_user_lang: {
        firstname: firstname,
        lastname: lastname,
        language_code: LanguageForm,
      },
    };
    let statusAPI = await updateClientUserAPI(dataAPI);
    if (statusAPI.message === "OK") {
      successSweetAlert({ text: t("notification_edit_info_successful") });
      setTimeout(() => {
        navigate(`/company/${CompanyId}/clientUser`);
      }, 1500);
    }
  };
  const fetchData = async () => {
    let dataAPI = await getClientUserAPI(paramsId);
    let { client_user_uid, email, tel, client_user_lang } = dataAPI;
    let { firstname, lastname } = client_user_lang;
    setClientUserId(client_user_uid);
    setValue("firstname", firstname);
    setValue("lastname", lastname);
    setValue("email", email);
    setValue("tel", tel);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <Box>
          <BreadcrumbsTitle
            props={{
              title: t("client_user_info"),
              dataLink: [
                {
                  text: t("client_user"),
                  link: `/company/${CompanyId}/clientUser`,
                },
                { text: t("client_user_info"), link: null },
              ],
            }}
          />
          <Box p={4}>
            <ClientUserForm
              props={{
                handleSubmit,
                onConfirm,
                register,
                errors,
                isClickable,
                setValue,
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default ClientUserDetail;
