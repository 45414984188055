import { t } from "i18next";
import * as yup from "yup";
import { validationSchemas } from "./data";
export const clientUserSchema = ({detail}) =>
  yup.object().shape({
    firstname: validationSchemas.name,
    lastname: validationSchemas.name,
    email: validationSchemas.email,
    role: detail ? validationSchemas.free : validationSchemas.numberSelect,
    username: detail ? validationSchemas.free : validationSchemas.name,
    tel: detail ? validationSchemas.free : validationSchemas.tel,
    password: detail ? validationSchemas.free : validationSchemas.password,
    confirmPassword: detail
      ? validationSchemas.free
      : yup
          .string()
          .oneOf([yup.ref("password"), null], "Passwords must match")
          .required(t("validate_format_please_fill_out_form")),
    businessSelect: detail
      ? validationSchemas.free
      : validationSchemas.markSelect,
  });
