import React, { useState, useEffect } from "react";
import {
  Box,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
  TextField,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import ImageLoginPage from "../../assets/images/ImageLoginPage.png";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { loginAPI } from "../../service/auth/login";
import Swal from "sweetalert2";
import { clearAllCookies } from "../../service/cookies/clearCookies";
import { SetCookie } from "../../service/cookies/setCookies";
import { TypographyError } from "../../components/ui/typography/typography-error";

import { loginSchema } from "../../components/validations/loginSchema";
import ButtonCustom from "../../components/ui/button";
import { t } from "i18next";
function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: async (values) => {
      try {
        await loginSchema.validate(values, { abortEarly: false });
        return {
          values,
          errors: {},
        };
      } catch (validationErrors) {
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        return {
          values,
          errors: errorsObj,
        };
      }
    },
  });
  const [isClickable, setIsClickable] = useState(false);
  const [password, setPassword] = useState({
    showPassword: false,
  });
  const onConfirmForm = async (register) => {
    setIsClickable(true);
    setTimeout(() => {
      setIsClickable(false);
    }, 3000);
    let { username, password } = register;
    let dataAPI = {
      username,
      password,
    };
    let statusAPI = await loginAPI(dataAPI);
    console.log("statusAPI", statusAPI);
    if (statusAPI.message === "Login Successfully") {
      SetCookie(
        "access_token",
        statusAPI.data.access_token,
        7,
        process.env.REACT_APP_COOKIES_DOMAIN_URL
      );
      window.location.replace("/company");
    } else if (statusAPI === "account_not_allow") {
      Swal.fire({
        title: "Please wait for approve from admin.",
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      Swal.fire({
        title: "Username or Password is incorrect.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const onChangePassword = () => (event) => {
    setValue("password", event.target.value);
  };
  const onClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };
  const onRegister = async () => {
    window.location.replace("/register");
  };

  useEffect(() => {
    clearAllCookies(process.env.REACT_APP_COOKIES_DOMAIN_URL);
  }, []);

  return (
    <Box sx={(theme) => ({ color: theme.palette.primary.main })}>
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        sx={{ height: "100vh" }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          sx={(theme) => ({
            borderRadius: 2,
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
            [theme.breakpoints.up("md")]: {
              display: "inline",
              backgroundColor: theme.palette.primary.main,
              p: 15,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up("xs")]: {
                width: "30%",
              },
              [theme.breakpoints.up("sm")]: {
                width: "100%",
              },
            })}
          >
            <img src={ImageLoginPage} alt="ImageLoginPage" width="100%" />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          lg={4}
          sx={(theme) => ({
            [theme.breakpoints.up("xs")]: {
              borderRadius: 2,
              p: 10,
            },
          })}
        >
          <Box>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <Box align="left">
                <img
                  // src={logoTecmove}
                  src="https://tecmove.net/wp-content/uploads/2022/10/Picture1.png"
                  alt="logoTecmove"
                  width="40%"
                />
              </Box>
              <Box>
                <Typography
                  sx={(theme) => ({
                    fontSize: "24px",
                  })}
                >
                  {t("auth_title")}
                </Typography>
                {/* <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.h12,
                    color: theme.palette.primary.main + "80",
                  })}
                >
                  {t("auth_subtitle")}
                </Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <TextField
                    {...register("username")}
                    name="username"
                    placeholder="Username"
                    id="username"
                    type="text"
                    fullWidth
                    error={Boolean(errors.username)}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <PersonIcon
                          sx={(theme) => ({
                            mr: 1,
                            color: theme.palette.primary.main,
                          })}
                        />
                      ),
                    }}
                  />
                </Box>
                <TypographyError textError={errors?.username} />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <OutlinedInput
                    type={password.showPassword ? "text" : "password"}
                    onChange={onChangePassword("password")}
                    {...register("password")}
                    name="password"
                    id="password"
                    placeholder="Password"
                    fullWidth
                    size="small"
                    startAdornment={
                      <LockIcon
                        sx={(theme) => ({
                          mr: 1,
                          color: theme.palette.primary.main,
                        })}
                      />
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={onClickShowPassword}
                          edge="end"
                        >
                          {password.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={Boolean(errors.password)}
                  />
                </Box>
                <TypographyError textError={errors?.password} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  my: 2,
                }}
              >
                <ButtonCustom
                  props={{
                    text: t("auth_login"),
                    variant: "contained",
                    color: "primary",
                    type: "submit",
                    isClickable: isClickable,
                    fullSize: true,
                    size: "large",
                  }}
                />
              </Box>
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.up("xs")]: {
                    display: "inline",
                  },
                  [theme.breakpoints.up("sm")]: {
                    display: "flex",
                    justifyContent: "center",
                  },
                  gap: 2,
                })}
              >
                <Typography>{t("auth_sign_up_question")}</Typography>
                <Typography
                  sx={{
                    textDecoration: "underline",
                    ":hover": { cursor: "pointer", opacity: 0.8 },
                  }}
                  onClick={() => onRegister()}
                >
                  {t("auth_sign_up")}
                </Typography>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}

      <Typography
        sx={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          opacity: ".2",
        }}
      >
        V{process.env.REACT_APP_VERSION_CONTROL}
      </Typography>
    </Box>
  );
}

export default Login;
