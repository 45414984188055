import React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import { TypographyError } from "../typography/typography-error";
// import TitleTextField from "../title/titleTextField";

const AutocompleteComponent = ({
  title,
  name,
  control,
  placeholder,
  errors,
  optionsData,
  onFunctionSelectName,
  clearErrors,
  setValue,
  disabled,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* <TitleTextField props={{ text: title }} /> */}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            size="small"
            {...field}
            // freeSolo
            error={errors[name]}
            options={optionsData}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors[name]}
                label={title}
                InputLabelProps={{ shrink: true }}
                placeholder={placeholder ? placeholder : null}
              />
            )}
            onChange={(_, value) => {
              setValue(name, value); // Set the value using setValue
              clearErrors(name); // Clear error for the field
            }}
            onSelect={(event) =>
              onFunctionSelectName
                ? onFunctionSelectName(name, event.target.value)
                : null
            }
            disabled={disabled}
          />
        )}
      />
      <TypographyError textError={errors[name]} />
    </Box>
  );
};

export default AutocompleteComponent;
