import axios from "axios";

export const getImage = (imageKey) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_UPLOAD_URL}/api/getImage?key=${imageKey}`,
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_API_UPLOAD_KEY_AUTH}`,
    },
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
