// src/MapWithMarker.js
import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fixing the default marker icon issue with Leaflet in React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const MapWithMarker = ({ props }) => {
  let { setAnchorMap, setCountry, setValue } = props;
  const [marker, setMarker] = useState(null);
  // const [address, setAddress] = useState({});

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
      );
      const data = await response.json();
      if (data && data.address) {
        let addressObj = {
          house_number: data.address.house_number || "",
          road: data.address.road || "",
          suburb: data.address.suburb || "",
          city:
            data.address.city ||
            data.address.town ||
            data.address.village ||
            "",
          state: data.address.state || "",
          postcode: data.address.postcode || "",
          country: data.address.country || "",
        };
        // setAddress(addressObj);
        convertToData(addressObj);
      } else {
        // setAddress({ error: "Address not found" });
      }
    } catch (error) {
      // setAddress({ error: "Failed to fetch address" });
    }
  };

  const convertToData = async (address) => {
    await setCountry(address.country);
    await setValue("country", address.country);
    await setValue(
      "city",
      address.city || address.town || address.village || ""
    );
    await setValue(
      "state",
      address.state ? address.state : address.suburb || "" || ""
    );
    await setValue("postalCode", address.postcode || "");
  };

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setMarker([lat, lng]);
        fetchAddress(lat, lng);
        setAnchorMap([lat, lng]);
      },
    });

    return marker ? <Marker position={marker} /> : null;
  };
  return (
    <div>
      <MapContainer
        center={[13.7563, 100.5018]}
        zoom={8}
        style={{ height: "30vh", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <LocationMarker />
      </MapContainer>
    </div>
  );
};

export default MapWithMarker;
