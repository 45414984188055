import { IconButton, Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
export function EditButton({ props }) {
  const { path } = props;
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start", gap: "1rem" }}>
      <IconButton
        aria-label="Example"
        onClick={() => navigate(path)}
        sx={{ boxShadow: 5 }}
      >
        <EditIcon />
      </IconButton>
    </Box>
  );
}
