import axios from "axios";
import {
  AccessTokenAPI,
  CompanyId,
  LanguageSystem,
} from "../localStorage/localStorageService";
import { expiredToken } from "../auth/expiredToken";
export const getAllCompanyAPI = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/getAllCompany?language_code=${LanguageSystem}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
      return error;
    });
};

export const createCompanyAPI = async (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/createCompany`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
export const getCompanyAPI = async (paramsId) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/getCompany?company_uid=${paramsId}&language_code=${LanguageSystem}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
export const updateCompanyAPI = async (dataAPI) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/updateCompany?company_uid=${CompanyId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
