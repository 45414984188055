import Swal from "sweetalert2";

export const expiredToken = (data) => {
  if (
    data.response.data === "accessToken expired" ||
    data.response.status === 401
  ) {
    Swal.fire({
      title: "Your session has expired.",
      icon: "warning",
      confirmButtonText: "BACK TO LOGIN",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.replace("/login");
      }
    });
  }
};
