import axios from "axios";
import { errorSweetAlert } from "../../components/ui/notification/swalNotification";
import { t } from "i18next";

export const loginAPI = async (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        errorSweetAlert({ text: t("auth_username_or_password_incorrect") });
      }
    });
};
