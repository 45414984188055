import { useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/locales/i18n";
import { AccessTokenAPI } from "./service/localStorage/localStorageService";
import AppRouter from "./components/router/appRouter";
import { MyProvider } from "./context/MyContext";

function App() {
  useEffect(() => {
    if (AccessTokenAPI === undefined) {
      window.location.replace(
        process.env.REACT_APP_SMART_PLATFORM_DOMAIN_URL + "/login"
      );
    } else {
      if (localStorage.getItem("language_system") === null) {
        localStorage.setItem("language_system", "en");
      }
      if (localStorage.getItem("language_form") === null) {
        localStorage.setItem("language_form", "en");
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <MyProvider>
      <StyledEngineProvider injectFirst>
        <I18nextProvider i18n={i18n}>
          <AppRouter />
        </I18nextProvider>
      </StyledEngineProvider>
    </MyProvider>
  );
}

export default App;
