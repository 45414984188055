import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { successSweetAlert } from "../../components/ui/notification/swalNotification";
import { useTranslation } from "react-i18next";
import { createClientUserAPI } from "../../service/clientUser/clientUserAPI";
import {
  CompanyId,
  LanguageForm,
} from "../../service/localStorage/localStorageService";
import BreadcrumbsTitle from "../../components/ui/breadcrumbs/breadcrumbsTitle";
import { clientUserSchema } from "../../components/validations/clientUserSchema";
import { useNavigate } from "react-router-dom";
import { getAllBusinessAPI } from "../../service/business/businessAPI";
import { useSchema } from "../../components/hooks/use-schema";
import ClientUserForm from "./clientUserForm";

function ClientUserCreate() {
  const {
    register,
    control,
    clearErrors,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(useSchema(clientUserSchema({ detail: false })));
  const [registerDone, setRegisterDone] = useState(false);
  const [isClickable, setIsClickable] = useState(false);
  const [anchorPassword, setAnchorPassword] = useState(null);
  const [clientBusinessSelect, setClientBusinessSelect] = useState([]);
  const [clientBusinessIdSelect, setClientBusinessIdSelect] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const navigate = useNavigate();
  const openAnchorPassword = Boolean(anchorPassword);
  const { t } = useTranslation();
  const [password, setPassword] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const onClickShowPassword = (name) => {
    setPassword({
      ...password,
      [name]: !password[name],
    });
  };
  const onConfirm = async (register) => {
    setIsClickable(true);
    setTimeout(() => {
      setIsClickable(false);
    }, 1000);
    let { username, password, email, tel, firstname, lastname, role } =
      register;
    let dataAPI = {
      username,
      password,
      email,
      tel,
      role_id: role,
      client_user_businesses: clientBusinessIdSelect,
      client_user_lang: {
        firstname: firstname,
        lastname: lastname,
        language_code: LanguageForm,
      },
    };
    let statusAPI = await createClientUserAPI(dataAPI);

    if (statusAPI.message === "OK") {
      successSweetAlert({ text: t("notification_create_info_successful") });
      navigate(`/company/${CompanyId}/clientUser`);
      setRegisterDone(true);
    }
  };
  const fetchData = async () => {
    let statusAPI = await getAllBusinessAPI();
    let statusBusinessBox = [];
    statusAPI.forEach((item) => {
      statusBusinessBox.push({
        id: item.id,
        name: item.business_lang.business_name,
      });
    });
    setBusinessList(statusBusinessBox);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("client_user_create"),
          dataLink: [
            {
              text: t("client_user"),
              link: `/company/${CompanyId}/clientUser`,
            },
            { text: t("client_user_create"), link: null },
          ],
        }}
      />
      <Box p={4}>
        <ClientUserForm
          props={{
            registerDone,
            handleSubmit,
            onConfirm,
            register,
            errors,
            password,
            onClickShowPassword,
            setAnchorPassword,
            isClickable,
            anchorPassword,
            openAnchorPassword,
            businessList,
            clearErrors,
            control,
            setValue,
            clientBusinessSelect,
            setClientBusinessSelect,
            setClientBusinessIdSelect,
          }}
        />
      </Box>
    </Box>
  );
}

export default ClientUserCreate;
