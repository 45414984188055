import { Box, styled } from "@mui/material";

export const GridContainerSize = styled(Box)(({ theme, padding, width }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  // margin: "auto",
  boxSizing: "border-box",

  // Apply dynamic padding and width based on props or use defaults
  padding: padding || "16px",
  width: width || "100%",

  [theme.breakpoints.up("sm")]: {
    width: width || "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: width || "75%",
  },
  [theme.breakpoints.up("lg")]: {
    padding: padding || "0 16px",
    width: width || "50%",
  },
}));
