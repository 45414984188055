import { Box, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BusinessId,
  CompanyId,
} from "../../service/localStorage/localStorageService";
import { t } from "i18next";
import { SetCookie } from "../../service/cookies/setCookies";
import { getAllBusinessAPI } from "../../service/business/businessAPI";
import Cookies from "js-cookie";
function BusinessChoosing({ props }) {
  let { menuActive } = props;
  const [businessValue, setBusinessValue] = useState(BusinessId);
  const [businessAll, setBusinessAll] = useState([]);
  const fetchDataBusiness = async () => {
    let dataAPI = await getAllBusinessAPI();
    setBusinessAll(dataAPI);
    setBusinessValue(BusinessId);
  };

  const onBusinessAll = async () => {
    Cookies.remove("business_uid");
    window.location.replace(`/company/${CompanyId}/business/`);
  };
  const onBusinessCreate = async () => {
    Cookies.remove("business_uid");
    window.location.replace(`/company/${CompanyId}/business/create`);
  };
  const onBusinessChoose = async (businessValue) => {
    let { business_uid, business_lang } = businessValue;
    SetCookie(
      "business_name",
      business_lang.business_name,
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    SetCookie(
      "business_uid",
      business_uid,
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    window.location.replace(
      `/company/${CompanyId}/business/${business_uid}/service`
    );
  };
  useEffect(() => {
    fetchDataBusiness();
  }, []);
  return (
    <Box sx={{ display: menuActive ? "none" : "inline" }}>
      {/* <Typography
        sx={(theme) => ({
          color: theme.palette.white.main,
          display: menuActive ? "none" : "flex",
          my: 1,
          ml: 1,
          fontFamily: "Kanit",
          fontSize: 12,
          opacity: 0.5,
        })}
        className="smoothTransition"
        align={menuActive && window.innerWidth > 600 ? "center" : "flex-start"}
      >
        {`${textId}`}
      </Typography> */}
      <Box
        sx={(theme) => ({
          boxSizing: "border-box",
          gap: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        })}
      >
        {/* <Typography sx={{ fontSize: 16 }}>Business</Typography> */}
        <Select
          value={businessValue}
          fullWidth
          size="small"
          sx={{ borderRadius: 2 }}
          align="center"
        >
          {/* <MenuItem value={1}>{BusinessName}</MenuItem> */}
          <MenuItem value={2} onClick={() => onBusinessAll()}>
            {t("navbar_see_all_business")}
          </MenuItem>
          <MenuItem value={3} onClick={() => onBusinessCreate()}>
            {t("navbar_add_business")}
          </MenuItem>
          <hr />
          <Typography
            sx={(theme) => ({
              fontSize: 12,
              ml: 2,
              color: theme.palette.primaryText.main + "80",
            })}
          >
            {t("navbar_all_business")}
          </Typography>
          {businessAll.map((business) => (
            <MenuItem
              value={business.business_uid}
              onClick={() => onBusinessChoose(business)}
            >
              {business.business_lang.business_name}
            </MenuItem>
          ))}
          {/* ... */}
        </Select>
      </Box>
    </Box>
  );
}

export default BusinessChoosing;
