import { Box } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CompanyId } from "../../service/localStorage/localStorageService";
import { createBusinessAPI } from "../../service/business/businessAPI";
import BreadcrumbsTitle from "../../components/ui/breadcrumbs/breadcrumbsTitle";
import { uploadImage } from "../../service/upload/uploadImage";
import { businessSchema } from "../../components/validations/businessSchema";
import { useToast } from "../../components/hooks/use-toast";
import { t } from "i18next";
import { useSchema } from "../../components/hooks/use-schema";
import BusinessForm from "./businessForm";
function BusinessCreate() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    clearErrors,
  } = useForm(useSchema(businessSchema));
  const [image, setImage] = useState();
  const [isClickable, setIsClickable] = useState(false);
  const [country, setCountry] = useState("");
  const navigate = useNavigate();
  const [anchorMap, setAnchorMap] = useState([
    18.788852690765257, 98.98393206985963,
  ]);
  const { successToast } = useToast();
  const onConfirm = async (register) => {
    setIsClickable(true);
    setTimeout(() => {
      setIsClickable(false);
    }, 3000);
    let newImage = "";
    if (image) {
      newImage = await uploadImage(image);
    }
    let {
      taxId,
      businessName,
      juristicName,
      businessType,
      country,
      city,
      state,
      postalCode,
      addressOne,
      addressTwo,
      vatStatus,
    } = register;

    let dataAPI = {
      tax_id: taxId,
      vat_status: vatStatus === t("business_vat_include") ? true : false,
      logo: newImage,
      business_lang: {
        business_name: businessName,
        juristic_name: juristicName,
        business_type: businessType,
      },
      business_address: {
        address_line_1: addressOne ? addressOne : null,
        address_line_2: addressTwo ? addressTwo : null,
        state,
        city,
        country,
        postcode_no: postalCode,
        lat: String(anchorMap[0]),
        long: String(anchorMap[1]),
      },
    };
    let statusAPI = await createBusinessAPI(dataAPI);
    if (statusAPI.message === "OK") {
      navigate(`/company/${CompanyId}/business`);
      successToast({ title: t("notification_create_info_successful") });
    }
  };

  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("business_create"),
          dataLink: [
            { text: t("business"), link: `/company/${CompanyId}/business` },
            { text: t("business_create"), link: null },
          ],
        }}
      />
      <Box p={4}>
        <BusinessForm
          props={{
            title: t("business_create"),
            handleSubmit,
            onConfirm,
            setImage,
            image,
            register,
            errors,
            setAnchorMap,
            country,
            setCountry,
            isClickable,
            anchorMap,
            control,
            setValue,
            clearErrors,
          }}
        />
      </Box>
    </Box>
  );
}

export default BusinessCreate;
