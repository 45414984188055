import * as yup from "yup";
import { validationSchemas } from "./data";
export const companySchema = yup.object().shape({
  companyName: validationSchemas.name,
  country: validationSchemas.name,
  city: validationSchemas.name,
  state: validationSchemas.name,
  postalCode: validationSchemas.name,
  // addressOne: validationSchemas.free,
  // addressTwo: validationSchemas.free,
});
