import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAllCompanyAPI } from "../../service/company/companyAPI";
import { Loading } from "../../components/ui/loading/loading";
import { SetCookie } from "../../service/cookies/setCookies";
import { t } from "i18next";
import Cookies from "js-cookie";
import NavbarCompany from "./navbarCompany";
import AddIcon from "@mui/icons-material/Add";
import CardItem from "./cardItem";
function Company() {
  const navigate = useNavigate();
  const [companyAll, setCompanyAll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    Cookies.remove("business_uid");
    Cookies.remove("company_uid");
    let dataAPI = await getAllCompanyAPI();
    setCompanyAll(dataAPI);
    setIsLoading(false);
  };

  const onDirectToBusiness = (companyValue) => {
    let { company } = companyValue;
    SetCookie(
      "company_name",
      company.company_lang.name,
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    SetCookie(
      "company_uid",
      company.company_uid,
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    window.location.replace(`/company/${company.company_uid}/business`);
  };
  const onEdit = (uid) => {
    SetCookie("company_uid", uid, 7, process.env.REACT_APP_COOKIES_DOMAIN_URL);
    window.location.replace("company/detail/" + uid);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <NavbarCompany
            props={{
              textBack: t("company_back_to_login"),
              onReDirectUrl: "/login",
              textOnDirect: t("notification_confirm_logout"),
            }}
          />
          <Box
            sx={(theme) => ({
              // backgroundColor: theme.palette.secondary.main,
              maxHeight: "400px",
              height: "100%",
              width: "100%",
              position: "absolute",
              zIndex: 1,
            })}
          >
            {window.innerWidth < 600 ? (
              <>
                <img
                  src="https://media.istockphoto.com/id/959532918/vector/abstract-desert-background.jpg?s=612x612&w=0&k=20&c=Dox4_Kcn_ofnp3w9LaS16f6Z6yKjP2YtFFWO9SrsUD0="
                  alt="bgCover"
                  width="100%"
                  style={{
                    objectFit: "cover",
                    height: "100vh",
                  }}
                />
              </>
            ) : (
              <>
                <img
                  src="https://media.istockphoto.com/id/959532918/vector/abstract-desert-background.jpg?s=612x612&w=0&k=20&c=Dox4_Kcn_ofnp3w9LaS16f6Z6yKjP2YtFFWO9SrsUD0="
                  alt="bgCover"
                  width="100%"
                  style={{
                    objectFit: "cover",
                    height: "100%",
                  }}
                />
              </>
            )}
          </Box>
          <Box
            sx={{
              p: 2,
              height: "92vh",
              boxSizing: "border-box",
              width: "100%",
              maxWidth: "1024px",
              margin: "auto",
              zIndex: 2,
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={(theme) => ({
                  fontFamily: "Kanit",
                  color: theme.palette.white.main,
                  textShadow: `1px 1px 4px ${theme.palette.secondary.main}`,
                  fontSize: 24,
                })}
              >
                {t("company_title")}
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                boxSizing: "border-box",
                overflow: "auto",
                height: "70vh",
                my: 1,
              })}
              align="center"
            >
              <Grid container>
                {window.innerWidth < 600 ? (
                  <Box
                    sx={{
                      position: "fixed",
                      bottom: 20,
                      right: 20,
                      zIndex: 999,
                    }}
                  >
                    <IconButton
                      onClick={() => navigate("create")}
                      sx={(theme) => ({
                        boxShadow: 2,
                        px: 2,
                        backgroundColor: theme.palette.secondaryText.main,
                        color: theme.palette.white.main,
                      })}
                    >
                      +
                    </IconButton>
                  </Box>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    sx={{ boxSizing: "border-box", p: 2 }}
                  >
                    <Box
                      sx={(theme) => ({
                        width: "100%",
                        height: "100%",
                        boxSizing: "border-box",
                        backgroundColor: theme.palette.white.main,
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        borderRadius: 2,
                        p: 4,
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: theme.palette.secondary.main,
                        ":hover": {
                          backgroundColor: theme.palette.bgSizeBarRight.main,
                          cursor: "pointer",
                        },
                      })}
                      onClick={() => navigate("create")}
                    >
                      <Box align="center">
                        <AddIcon sx={{ fontSize: 64 }} />
                        <Typography sx={{ fontSize: 18 }}>
                          {t("company_create")}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
                {companyAll.length > 0 ? (
                  <>
                    {companyAll.map((company, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        sx={{ boxSizing: "border-box", p: 2 }}
                      >
                        <CardItem
                          props={{
                            imgUrl: company.company.logo,
                            title: company.company.company_lang.name,
                            data: company,
                            onClickDirect: onDirectToBusiness,
                            onClickDirectText: t("business_view_business"),
                            onClickEdit: onEdit,
                            dataEdit: company.company.company_uid,
                          }}
                        />
                      </Grid>
                    ))}
                  </>
                ) : null}
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Company;
