import React from "react";
import { Box, Typography } from "@mui/material";
function TitleHeaderForm({ props }) {
  const { text } = props;
  return (
    <Box
      sx={(theme) => ({
        color: theme.palette.primaryText.main,
        // borderBottom: "1px solid #00000020",
        width: "100%",
        py: 1,
        px: 2,
        boxSizing: "border-box",
      })}
    >
      <Typography variant="h6" sx={(theme) => ({ fontFamily: "Kanit", fontWeight: 500 })}>
        {text}
      </Typography>
    </Box>
  );
}

export default TitleHeaderForm;
