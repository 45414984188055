import { Typography } from "@mui/material";
import React from "react";

function TypographyTitleSidebar({ props }) {
  let { menuExpanded, text } = props;
  return (
    <Typography
      sx={(theme) => ({
        color: theme.palette.primaryText.main+"90",
        display: "flex",
        my: 1,
        ml: menuExpanded ? 0 : 1,
        fontFamily: "Kanit",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 10,
        // opacity: 0.5,
        justifyContent: menuExpanded ? "center" : "flex-start",
      })}
      className="smoothTransition"
    >
      {text}
    </Typography>
  );
}

export default TypographyTitleSidebar;
