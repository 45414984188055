import axios from "axios";
import {
  AccessTokenAPI,
  CompanyId,
  LanguageSystem,
} from "../localStorage/localStorageService";
import { expiredToken } from "../auth/expiredToken";

export const createBusinessAPI = async (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/storeBusiness?company_uid=${CompanyId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
export const updateBusinessAPI = async (dataAPI) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/updateBusiness?company_uid=${CompanyId}`,

    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
export const getBusinessAPI = async (businessId) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/getBusiness?business_uid=${businessId}&company_uid=${CompanyId}&language_code=${LanguageSystem}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
export const getAllBusinessAPI = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/getAllBusiness?company_uid=${CompanyId}&language_code=${LanguageSystem}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
