import { Box, IconButton } from "@mui/material";
import React from "react";
import LogoSideBar from "../../assets/images/tecmoveLogo.png";
import { Logout } from "@mui/icons-material";
import Swal from "sweetalert2";
import { t } from "i18next";

function NavbarCompany({ props }) {
  const onLogout = () => {
    Swal.fire({
      title: t("notification_confirm_logout"),
      showCancelButton: true,
      confirmButtonText: t("button_yes_or_confirm"),
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.replace("/login");
      }
    });
  };
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#aaaaaa",
        backgroundColor: "#fff",
        borderBottom: "1px solid #aaaaaa60",
        height: "8vh",
        boxSizing: "border-box",
        px: 2,
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          gap: 2,
          px: 2,
        })}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={LogoSideBar} width="20%" alt="logo" />
        </Box>
      </Box>
      {window.location.pathname === "/company" ? (
        <IconButton onClick={() => onLogout()}>
          <Logout />
        </IconButton>
      ) : null}
    </Box>
  );
}

export default NavbarCompany;
