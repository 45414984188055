import { Box, Typography } from "@mui/material";
import React from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
function BoxUploadFile({ props }) {
  let { heightCrop, widthCrop } = props;
  return (
    <Box
      sx={(theme) => ({
        border: "2px solid #cccccc80",
        borderRadius: 1,
        gap: 1,
        p: 2,
        boxSizing: "border-box",
        justifyContent: "center",
        alignItems: "center",
        ":hover": { opacity: 0.8, cursor: "pointer" },
        backgroundColor: "#cccccc50",

        display: "flex",
        minHeight: "256px",
        minWidth: "256px",
      })}
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            backgroundColor: "#00000090",
            color: "#fff",
            borderRadius: 1,
            boxSizing: "border-box",
            p: 1,
            justifyContent: "center",
            algnItems: "center",
          }}
        >
          <AddAPhotoIcon />
          <Typography>Add Photo</Typography>
        </Box>
        <Box my={1} align="center">
          <Typography sx={{ opacity: 0.5 }}>
            Recommended size <br />
            {widthCrop} x {heightCrop} PX <br /> Format suggest JPG,PNG
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default BoxUploadFile;
