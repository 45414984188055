import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { t } from "i18next";

function RegisterApproval(props) {
  let { buttonText, linkUrl } = props;
  const onRedirect = () => {
    window.location.replace(linkUrl);
  };
  return (
    <Box align="center">
      <img
        src={"https://tecmove.net/wp-content/uploads/2022/10/Picture1.png"}
        width={"100%"}
        alt="adminApprove"
      />
      <Typography
        sx={(theme) => ({
          color: theme.palette.primaryText.main,
          fontSize: 18,
        })}
      >
        {t("register_waiting_for_approval")}
      </Typography>

      <Button sx={{ mt: 2 }} variant="contained" onClick={() => onRedirect()}>
        {buttonText}
      </Button>
    </Box>
  );
}

export default RegisterApproval;
