import axios from "axios";
import { AccessTokenAPI, CompanyId } from "../localStorage/localStorageService";

export const getAllPairService = async () => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/getAllPairService?company_uid=${CompanyId}`,
    headers: { Authorization: `Bearer ${AccessTokenAPI}` },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
