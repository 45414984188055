import { Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { EditButton } from "../../components/ui/button/EditButton";
import { getAllClientUserAPI } from "../../service/clientUser/clientUserAPI";
import { useTranslation } from "react-i18next";
import DataTable from "../../components/ui/table/dataTable";

function ClientUser() {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        accessorKey: "action",
        size: 20,
        header: "Action",
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <EditButton
              props={{ path: String(row.original.client_user_uid) }}
            />
          </Box>
        ),
      },
      {
        accessorKey: "client_user_uid", //access nested data with dot notation
        header: "client Id",
        size: 100,
      },
      {
        accessorKey: "username", //access nested data with dot notation
        header: "Username",
        size: 100,
      },
      {
        accessorKey: "client_user_lang.firstname", //access nested data with dot notation
        header: "First name",
        size: 100,
      },
      { accessorKey: "tel", header: "Tel", size: 100 },
      {
        accessorKey: "client_user_lang.lastname",
        header: "Last name",
        size: 100,
      },
    ],
    []
  );
  const fetchData = async () => {
    let dataAPI = await getAllClientUserAPI();
    if (dataAPI) {
      setData(dataAPI);
    }
    setIsLoadingData(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box>
      <DataTable
        rows={data}
        columns={columns}
        isLoadingData={isLoadingData}
        detailPath={"/clientUser/detail/"}
        title={t("clientUser.clientUser")}
        createButton={t("clientUser.clientUserCreate")}
      />
    </Box>
  );
}

export default ClientUser;
