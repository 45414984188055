import React from "react";
import { Box, Typography } from "@mui/material";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import MapWithMarker from "./mapWithMarker";
export function MapAddress({ props }) {
  let { anchorMap, setAnchorMap, setCountry, setValue } = props;
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 1 }}>
        <AddLocationAltIcon />
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Map (latitude,longitude)
        </Typography>
        <Typography
          sx={(theme) => ({ color: theme.palette.secondaryText.main })}
        >
          {String(anchorMap[0]) + " , " + String(anchorMap[1])}
        </Typography>
      </Box>

      <Box p={2}>
        <MapWithMarker
          props={{ anchorMap, setAnchorMap, setCountry, setValue }}
        />
        {/* <Map height={300} defaultCenter={anchorMap} defaultZoom={4}>
          <Draggable
            offset={[60, 87]}
            anchor={anchorMap}
            onDragEnd={setAnchorMap}
          >
            <img
              src={
                "https://www.pngall.com/wp-content/uploads/2017/05/Map-Marker-PNG-File.png"
              }
              width={100}
              height={95}
              alt="Pin"
            />
          </Draggable>
        </Map> */}
      </Box>
    </Box>
  );
}
