import { Box, Divider, Typography } from "@mui/material";
import React from "react";
// import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { t } from "i18next";
// import DirectionsIcon from "@mui/icons-material/Directions";
// import EastIcon from "@mui/icons-material/East";
import logoSPOS from "../../assets/images/LogoSPOS.png";
import logoSCRM from "../../assets/images/LogoSCRM.png";
function ServiceCard({ props }) {
  let {
    itemData,
    //  onPairService,
    onDirectToService,
    //  statusPair
  } = props;
  return (
    <Box
      sx={(theme) => ({
        boxSizing: "border-box",
        // p: 2,
        backgroundColor: "#ffffff",
        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, .03)",
        borderRadius: "0.75rem",
        border: "1px solid #F1F1F1",
        height: "100%",
        position: "relative",
        alignItems: "center",
        // display: "flex",
        justifyContent: "center",
      })}
      className="transitionEaseInOut"
    >
      <Box
        sx={{
          pt: 2,
          px: 2,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box
          align="left"
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          {itemData.name.includes("SPOS") ? (
            <img src={logoSPOS} alt="logoSPOS" width={"50%"} />
          ) : (
            <>
              {itemData.name.includes("MEMBERSHIP") ? (
                <img src={logoSCRM} alt="logoSCRM" width={"50%"} />
              ) : (
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    fontFamily: "Kanit",
                    textTransform: "uppercase",
                    gap: 1,
                    justifyContent: "space-between",
                  }}
                >
                  {itemData.name}
                </Typography>
              )}
            </>
          )}
        </Box>
        <Box
          align="left"
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 400,
              }}
            >
              Package :
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                textTransform: "capitalize",
              })}
            >
              {itemData.data.package_uid.split("_")[1]}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 400,
              }}
            >
              Update at :
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                textTransform: "capitalize",
              })}
            >
              {itemData.date}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 1, width: "100%" }} />
      <Box
        sx={(theme) => ({
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          // textTransform: "uppercase",
          // textDecoration: "underline",
          // textDecorationStyle: "dashed",
          color: theme.palette.info.main,
          pb: 1,
          boxSizing: "border-box",
          ":hover": { opacity: 0.5, cursor: "pointer" },
        })}
        onClick={() => onDirectToService()}
        align="center"
      >
        {/* <DirectionsIcon /> */}
        <Typography
          sx={(theme) => ({
            fontSize: 14,
            borderBottom: `1px dashed ${theme.palette.info.main}`,
          })}
        >
          {itemData.status === "waiting"
            ? t("button_waiting_for_approval")
            : t("global_direct_to_service")}
        </Typography>
        {/* <EastIcon color="primary" sx={{ fontSize: 16 }} /> */}
      </Box>
    </Box>
  );
}

export default ServiceCard;
