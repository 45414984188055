import { t } from "i18next";
import * as yup from "yup";
import { validationSchemas } from "./data";

export const userRegisterSchema = yup.object().shape({
  firstname: validationSchemas.name,
  lastname: validationSchemas.name,
  email: validationSchemas.email,
  username: validationSchemas.name,
  tel: validationSchemas.tel,
  password: validationSchemas.password,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required(t("validate_please_fill_out_information")),
});
