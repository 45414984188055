import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
} from "../../localStorage/localStorageService";
import { expiredToken } from "../../auth/expiredToken";

export const getSPOSRegistrationAPI = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/spos/getSposRegistration?business_uid=${BusinessId}&company_uid=${CompanyId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
