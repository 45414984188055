import React from "react";
import MaterialReactTable from "material-react-table";
import { Box } from "@mui/material";
import { Loading } from "../loading/loading";
import BreadcrumbsTitle from "../breadcrumbs/breadcrumbsTitle";
import ButtonCustom from "../button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
const DataTable = (props) => {
  let { rows, columns, title, createButton, isLoadingData } = props;
  const navigate = useNavigate();
  return (
    <Box>
      {title ? (
        <BreadcrumbsTitle
          props={{
            title: title,
          }}
        />
      ) : (
        <></>
      )}
      <Box sx={(theme) => ({ p: 4 })}>
        {rows ? (
          <>
            <MaterialReactTable
              enableStickyHeader
              columns={columns}
              data={rows}
              state={{ isLoading: isLoadingData }}
              enableColumnActions={false}
              initialState={{
                showGlobalFilter: true,
                pagination: {
                  pageSize: 25,
                  pageIndex: 0,
                },
              }}
              muiSearchTextFieldProps={{
                variant: "outlined",
                size: "small",
                placeholder: "Search",
                sx: { borderRadius: 2, p: 2 },
                InputLabelProps: {
                  shrink: true,
                },
              }}
              enableToolbarInternalActions={false}
              muiTableProps={{
                sx: {
                  tableLayout: "fixed",
                },
              }}
              muiTableContainerProps={{
                sx: { maxHeight: window.innerHeight * 0.54 },
              }}
              muiTablePaginationProps={{
                rowsPerPageOptions: [25, 50, 100],
                showFirstButton: true,
                showLastButton: true,
              }}
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: 2,
                    flexWrap: "wrap",
                  }}
                >
                  {createButton ? (
                    <>
                      <ButtonCustom
                        props={{
                          text: t("button_create"),
                          variant: "contained",
                          color: "primary",
                          icon: <AddIcon />,
                          onClick: () => navigate("create"),
                          isClickable: false,
                          fullSize: false,
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              )}
              // renderTopToolbarCustomActions={({ table }) => (
              //   <Box
              //     sx={{
              //       display: "flex",
              //       gap: "1rem",
              //       p: "0.5rem",
              //       flexWrap: "wrap",
              //     }}
              //   >
              //     <Button
              //       color="primary"
              //       onClick={handleExportData}
              //       startIcon={<FileDownloadIcon />}
              //       variant="contained"
              //     >
              //       Export
              //     </Button>
              //   </Box>
              // )}
            />
          </>
        ) : (
          <>
            <Loading />
          </>
        )}
      </Box>
    </Box>
  );
};

export default DataTable;
