import { Box, Typography } from "@mui/material";

export const TypographyError = (props) => {
  const { text, textError } = props;
  return (
    <Box>
      {textError ? (
        <Typography
          sx={(theme) => ({
            color: theme.palette.error.main,
            fontFamily: "Kanit",
            fontSize: 12,
          })}
        >
          {textError}*
        </Typography>
      ) : (
        <Typography
          align="left"
          sx={(theme) => ({
            fontFamily: "Kanit",
          })}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};
