export const useSchema = (schemaValue) => {
  const schemaData = {
    mode: "onBlur",
    resolver: async (values) => {
      try {
        await schemaValue.validate(values, { abortEarly: false });
        return {
          values,
          errors: {},
        };
      } catch (validationErrors) {
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        return {
          values,
          errors: errorsObj,
        };
      }
    },
  };
  return schemaData;
};
