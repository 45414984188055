import React, { useEffect, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { Box, Button, FormHelperText, Skeleton } from "@mui/material";
import { base64StringToFile } from "../../ui/upload/base64StringToFile";
import BoxUploadFile from "../boxImage/boxUploadFile";
import { BoxImage } from "../boxImage/boxImage";
import { convertToPNG } from "./convertHEIC";
import { isBrowser } from "react-device-detect";
function ImageCrop({ props }) {
  let { setImage, image, heightCrop, widthCrop, label, heightView, widthView } =
    props;
  const imgRef = useRef();
  const previewCanvasRef = useRef();
  const [completedCrop, setCompletedCrop] = useState();
  const [imageUpload, setImageUpload] = useState(null);

  const [crop, setCrop] = useState(null);
  const aspect = widthCrop || heightCrop ? widthCrop / heightCrop : 1 / 1;
  const [isConfirm, setIsConfirm] = useState(false);
  const onConfirmCrop = async (status) => {
    localStorage.removeItem("uploadImage");
    if (status === "crop") {
      let dataImg = previewCanvasRef.current.toDataURL();
      let fileUploadFromCanvas = await base64StringToFile(dataImg, "image");
      let img = new Image();
      let resizedImageUrl = "";
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = widthCrop;
        canvas.height = heightCrop;
        context.drawImage(img, 0, 0, widthCrop, heightCrop);
        resizedImageUrl = canvas.toDataURL();
        let fileConvertResize = base64StringToFile(
          resizedImageUrl,
          `image${widthCrop}.png`
        );
        setImage(fileConvertResize);
        setIsConfirm(true);
      };
      img.src = URL.createObjectURL(fileUploadFromCanvas);
    } else {
      let img = new Image();
      let resizedImageUrl = "";
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = widthCrop;
        canvas.height = heightCrop;
        context.drawImage(img, 0, 0, widthCrop, heightCrop);
        resizedImageUrl = canvas.toDataURL();
        let fileConvertResize = base64StringToFile(
          resizedImageUrl,
          `image${widthCrop}.png`
        );
        setImage(fileConvertResize);
        setIsConfirm(true);
      };
      img.src = imageUpload;
    }
  };
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  const onUploadFile = async (e) => {
    localStorage.setItem("uploadImage", true);
    const file = e.target.files[0];
    let newImage = null;
    if (file.type === "image/heic") {
      const pngBlob = await convertToPNG(file);
      newImage = URL.createObjectURL(pngBlob);
    } else {
      newImage = URL.createObjectURL(file);
    }
    setCrop(null);
    setCompletedCrop(null);

    setImageUpload(newImage);
    setImage(null);
    setIsConfirm(false);
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  function onCropImage(crop) {
    setCompletedCrop(crop);
  }
  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        1, //scale === 1
        0 // rotate === 0
      );
    }
  }, [completedCrop]);
  return (
    <>
      <Box
        sx={(theme) => ({
          position: "relative",
          p: 2,
          boxSizing: "border-box",
          width: "100%",
        })}
        align="center"
      >
        {isBrowser ? (
          <>
            {image ? (
              <Box>
                <label htmlFor={`upload-crop-new-image-${label}`}>
                  <BoxImage
                    image={image}
                    setImage={setImage}
                    setImageUpload={setImageUpload}
                    iconUpload={true}
                    width={widthView ? widthView : widthCrop}
                    height={heightView ? heightView : heightCrop}
                  />
                  <input
                    type="file"
                    id={`upload-crop-new-image-${label}`}
                    style={{ display: "none" }}
                    onChange={onUploadFile}
                  />
                </label>
              </Box>
            ) : (
              <>
                {imageUpload ? (
                  <Box align="center" sx={{ width: "256px", height: "100%" }}>
                    {!crop ? (
                      <Skeleton
                        sx={{
                          borderRadius: "10px",
                        }}
                        variant="rectangular"
                        width={widthCrop ? widthCrop : "100%"}
                        height={widthCrop ? widthCrop : "100%"}
                      />
                    ) : null}
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => onCropImage(c)}
                      aspect={aspect}
                    >
                      <img
                        ref={imgRef}
                        src={imageUpload}
                        style={{
                          width: "100%",
                          opacity: !crop ? 0 : 1,
                        }}
                        alt="Crop me"
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                    <br />
                    <FormHelperText sx={{ textAlign: "center", mb: 1 }}>
                      Crop image to fit size
                    </FormHelperText>
                    <Button
                      variant="contained"
                      onClick={() => onConfirmCrop("crop")}
                      className="itemPopUp"
                    >
                      Confirm Upload
                    </Button>
                  </Box>
                ) : (
                  <label htmlFor={`upload-crop-image-${label}`}>
                    <BoxUploadFile
                      props={{
                        widthCrop,
                        heightCrop,
                        widthView,
                        heightView,
                      }}
                    />
                    <input
                      type="file"
                      id={`upload-crop-image-${label}`}
                      style={{ display: "none" }}
                      onChange={onUploadFile}
                    />
                  </label>
                )}
                {completedCrop && (
                  <>
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: "1px solid black",
                          objectFit: "contain",
                          width: completedCrop.width,
                          height: completedCrop.height,
                          display: "none",
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {/* {imageUpload && isConfirm === false ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => onConfirmCrop("crop")}
                  className="itemPopUp"
                >
                  Confirm Upload
                </Button>
              </>
            ) : null} */}
          </>
        ) : (
          <>
            {image ? (
              <Box>
                <label htmlFor={`upload-crop-new-image-mobile-${label}`}>
                  <BoxImage
                    image={image}
                    setImage={setImage}
                    setImageUpload={setImageUpload}
                    iconUpload={true}
                    width={"100%"}
                  />
                  <input
                    type="file"
                    id={`upload-crop-new-image-mobile-${label}`}
                    style={{ display: "none" }}
                    onChange={onUploadFile}
                  />
                </label>
              </Box>
            ) : (
              <label htmlFor={`upload-crop-image-mobile-${label}`}>
                {imageUpload ? (
                  <>
                    <img
                      ref={imgRef}
                      src={imageUpload}
                      width={"100%"}
                      alt="Crop me"
                      onLoad={onImageLoad}
                    />
                  </>
                ) : (
                  <>
                    <BoxUploadFile
                      props={{
                        heightCrop,
                        widthCrop,
                      }}
                    />
                    <input
                      type="file"
                      id={`upload-crop-image-mobile-${label}`}
                      style={{ display: "none" }}
                      onChange={onUploadFile}
                    />
                  </>
                )}
              </label>
            )}
            {imageUpload && isConfirm === false ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => onConfirmCrop("nocrop")}
                  className="itemPopUp"
                >
                  Confirm Upload
                </Button>
              </>
            ) : null}
          </>
        )}
      </Box>
    </>
  );
}

export default ImageCrop;
