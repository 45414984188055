import React from "react";
import countries from "../utils/countries.json";
import { useTranslation } from "react-i18next";
// import TitleForm from "../ui/title/titleForm";
import { BoxWrapper } from "../ui/box/boxWrapper";
import AutocompleteComponent from "../ui/autocomplete/autoCompleteComponent";
import TextfieldComponent from "../ui/textfield/textfieldComponent";

function AddressForm({ props }) {
  let {
    register,
    errors,
    setAnchorMap,
    control,
    setValue,
    clearErrors,
    setCountry,
  } = props;
  const onSelectCountry = (name, countryName) => {
    setCountry(countryName);
    try {
      let findCountry = countries.find((item) => item.name === countryName);
      setAnchorMap([findCountry.latitude, findCountry.longitude]);
    } catch {
      console.log("not found");
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {/* <TitleForm props={{ text: t("address.addressInfo") }} /> */}
      <BoxWrapper>
        <AutocompleteComponent
          title={t("address_country")}
          name="country"
          control={control}
          setValue={setValue}
          errors={errors}
          clearErrors={clearErrors}
          onFunctionSelectName={onSelectCountry}
          placeholder={t("validate_format_please_fill_out_form")}
          optionsData={countries.map((option) => option.name)}
          fullGrid={true}
        />
        <TextfieldComponent
          title={t("address_city")}
          name="city"
          register={register}
          placeholder={t("validate_format_please_fill_out_form")}
          errors={errors}
        />
      </BoxWrapper>
      <BoxWrapper>
        <TextfieldComponent
          title={t("address_state")}
          name="state"
          register={register}
          placeholder={t("validate_format_please_fill_out_form")}
          errors={errors}
        />
        <TextfieldComponent
          title={t("address_postal_code")}
          name="postalCode"
          register={register}
          placeholder={t("validate_format_please_fill_out_form")}
          errors={errors}
        />
      </BoxWrapper>
      <BoxWrapper>
        <TextfieldComponent
          title={t("address_additional_address") + " (Optional)"}
          name="addressOne"
          register={register}
          placeholder={t("Optional")}
          errors={errors}
        />
        {/* <TextfieldComponent
            title={t("address_additional_address_two")+" (Optional)"}
            name="addressTwo"
            register={register}
            placeholder={t("Optional")}
            errors={errors}
          /> */}
      </BoxWrapper>
    </>
  );
}

export default AddressForm;
