import { Box, Button } from "@mui/material";
import React from "react";
import imageNotFound from "../../../assets/images/errorPageNotFound.png";
function PageNotFound() {
  const onBackToLogin = () => {
    localStorage.clear();
    window.location.replace("/login");
  };
  return (
    <div>
      <Box
        sx={{
          position: "absolute",
          transform: "translate(-50%,-50%)",
          left: "50%",
          top: "50%",
        }}
      >
        <Box sx={(theme) => ({ display: "grid", placeItems: "center" })}>
          <img src={imageNotFound} alt="notFound" width="50%" />
          <Button
            variant="contained"
            onClick={() => onBackToLogin()}
            sx={{ my: 2 }}
          >
            Back to login
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default PageNotFound;
