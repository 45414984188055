import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { th } from "./th";
import { en } from "./en";
// the translations
const resources = {
  en: {
    translation: en,
  },
  th: {
    translation: th,
  },
};
const languageSystem = localStorage.getItem("language_system");
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: languageSystem !== null ? languageSystem : "en", // default language

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
