import toast from "react-hot-toast";
export const useToast = () => {
  const successToast = (props) => {
    return toast.success(props.title, {
      duration: 5000,
      style: {
        fontFamily: "Kanit",
        backgroundColor: "#fff",
        display: "flex",
        width: "100%",
        color: "#1B7530",
      },
    });
  };

  // Example : successToast({ title: "แก้ไขข้อมูลสำเร็จ" });

  const errorToast = (props) =>
    toast.error(props.title, {
      duration: 5000,
      style: {
        fontFamily: "Kanit",
        backgroundColor: "#fff",
        display: "flex",
        width: "100%",
        color: "#dc3545",
      },
    });

  // Example : errorToast({ title: "เกิดข้อผิดพลาด" });

  return { successToast, errorToast };
};
