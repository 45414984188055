import { Typography } from "@mui/material";

export const TypographyHeadPage = ({ props }) => {
  return (
    <Typography
      sx={(theme) => ({
        fontSize: theme.typography.h4,
        fontWeight: 700,
      })}
    >
      {props.text}
    </Typography>
  );
};
