import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Drawer } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import MenuItem from "./menuItem";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import AirplayIcon from "@mui/icons-material/Airplay";
import TypographyTitleSidebar from "./typographyTitleSidebar";
import { ListItemMenu } from "../../data/listItemMenu";
import { t } from "i18next";
import LogoSideBar from "../../assets/images/tecmoveLogo.png";
import LogoSideBarMini from "../../assets/images/logoTecmoveMini.png";
import { Loading } from "../ui/loading/loading";
import { ListItemService } from "../../data/listItemService";
import CompanyChoosing from "./companyChoosing";
import {
  BusinessId,
  BusinessName,
  CompanyName,
} from "../../service/localStorage/localStorageService";
import { MenuOpen } from "@mui/icons-material";
import BusinessChoosing from "./businessChoosing";
function Sidebar(props) {
  const listItemMenu = ListItemMenu();
  const listItemService = ListItemService();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [menuExpandedSub, setMenuExpandedSub] = useState(false);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [drawerBar, setDrawerBar] = useState(false);

  const fetchData = async () => {
    let sidebarExpandedSS =
      localStorage.getItem("side_bar_expanded") === "expand" ? false : true;
    setMenuExpanded(sidebarExpandedSS);
    const currentPath = location.pathname;
    listItemMenu.forEach((itemMenu) => {
      if (itemMenu.subMenus !== undefined) {
        itemMenu.subMenus.forEach((itemMenuSub) => {
          if (currentPath.includes(itemMenuSub.to)) {
            setExpanded(itemMenu);
          }
        });
      } else {
        if (currentPath.includes(itemMenu.to)) {
          setExpanded(itemMenu);
        }
      }
    });
    listItemService.forEach((itemMenu) => {
      if (itemMenu.subMenus !== undefined) {
        itemMenu.subMenus.forEach((itemMenuSub) => {
          if (currentPath.includes(itemMenuSub.to)) {
            setExpanded(itemMenu);
          }
        });
      } else {
        if (currentPath.includes(itemMenu.to)) {
          setExpanded(itemMenu);
        }
      }
    });

    setIsLoading(false);
  };
  const listDrawer = () => (
    <Box
      sx={(theme) => ({
        width: 300,
        height: "100vh",
        overflow: "auto",
        boxSizing: "border-box",
        p: 1,
      })}
      role="presentation"
      className="smoothTransition"
    >
      <img
        src="https://tecmove.net/wp-content/uploads/2022/10/Picture1.png"
        width="70%"
        alt="logo"
      />
      <TypographyTitleSidebar
        props={{
          text: menuExpanded ? t("company") : `${t("company")}`,
          menuExpanded,
        }}
      />
      <CompanyChoosing
        props={{
          textId: CompanyName,
          chipText: t("company_choose_company"),
        }}
      />
      <TypographyTitleSidebar
        props={{
          text: menuExpanded
            ? t("side_bar_manage_business")
            : `${t("side_bar_manage_business")}`,
          menuExpanded,
        }}
      />
      {listItemMenu.map((menuItem, index) => (
        <MenuItem
          props={{
            menuExpandedSub,
            setMenuExpandedSub,
            menuItem,
            menuExpanded,
            setDrawerBar,
            expanded,
            setExpanded,
            setMenuExpanded,
            drawerBar,
          }}
        />
      ))}
    </Box>
  );
  const onMenuExpanded = (value) => {
    setMenuExpanded(value);
    setMenuExpandedSub(false);
    localStorage.setItem("side_bar_expanded", value ? "collapse" : "expand");
  };
  const onDrawerMobile = (value) => {
    setDrawerBar(value);
    setMenuExpanded(false);
  };
  useEffect(() => {
    if (localStorage.getItem("side_bar_expanded") === undefined) {
      localStorage.setItem("side_bar_expanded", "expand");
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {window.innerWidth < 600 ? (
            <>
              <Box
                container
                direction="row"
                sx={{
                  textAlign: "start",
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxSizing: "border-box",
                    px: 2,
                    boxShadow: 5,
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    })}
                  >
                    <Box
                      sx={{
                        gap: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        size="large"
                        edge="start"
                        aria-label="menu"
                        onClick={() => onDrawerMobile(true)}
                      >
                        <MenuIcon />
                      </IconButton>
                      <AirplayIcon />
                      <Typography
                        sx={(theme) => ({
                          fontFamily: "Kanit",
                        })}
                      >
                        Client Platform
                      </Typography>
                    </Box>
                    {/* <SelectLanguage /> */}
                  </Box>
                </Box>
                <Box
                  sx={(theme) => ({
                    height: "95vh",
                    overflow: "auto",
                    boxSizing: "border-box",
                    backgroundColor: theme.palette.bgSizeBarRight.main,
                  })}
                >
                  <Outlet />
                </Box>
                <Drawer
                  anchor={"left"}
                  open={drawerBar}
                  onClose={() => onDrawerMobile(false)}
                >
                  {listDrawer()}
                </Drawer>
              </Box>
            </>
          ) : (
            <>
              <Grid
                container
                direction="row"
                sx={{
                  textAlign: "start",
                  position: "relative",
                }}
              >
                <Typography
                  sx={(theme) => ({
                    position: "absolute",
                    bottom: 10,
                    left: 30,
                    zIndex: 99,
                    color: theme.palette.secondaryText.main + "50",
                  })}
                >
                  V.{process.env.REACT_APP_VERSION_CONTROL}
                </Typography>
                <Grid
                  item
                  xs={12}
                  sm={menuExpanded ? 1 : 3.5}
                  md={menuExpanded ? 1 : 3.5}
                  lg={menuExpanded ? 0.8 : 2}
                  className="smoothTransition"
                  sx={(theme) => ({
                    height: "100vh",
                    boxSizing: "border-box",
                    // boxShadow: "2px 8px 20px #00000020",
                    borderRight: "1px solid #cccccc70",
                    zIndex: 3,
                    // backgroundColor: theme.palette.secondary.main,
                    backgroundColor: theme.palette.white.main,
                  })}
                >
                  <Box>
                    <Box
                      sx={(theme) => ({
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: menuExpanded
                          ? "center"
                          : "space-between",
                        height: "64px",
                        px: 2,
                        position: "relative",
                      })}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: menuExpanded ? "none" : "flex",
                          alignItems: "center",
                          gap: 1,
                          px: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={LogoSideBar}
                            style={{ width: "120px" }}
                            alt="logo"
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <IconButton
                          size="large"
                          aria-label="menu"
                          onClick={() => onMenuExpanded(!menuExpanded)}
                        >
                          {menuExpanded ? (
                            <MenuOpen
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontSize: 30,
                                transform: "rotate(180deg)",
                              })}
                            />
                          ) : (
                            <MenuOpen
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontSize: 30,
                              })}
                            />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    <Box sx={(theme) => ({ p: 2 })}>
                      <Box
                        sx={(theme) => ({
                          height: "86vh",
                          overflow: "auto",
                          boxSizing: "border-box",
                          position: menuExpanded ? null : "relative",
                          [theme.breakpoints.down("md")]: {
                            height: "95vh",
                          },
                        })}
                      >
                        {!menuExpanded ? null : (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={LogoSideBarMini}
                                width="50%"
                                alt="logo"
                              />
                            </Box>
                          </>
                        )}
                        {menuExpanded ? (
                          <></>
                        ) : (
                          <>
                            {/* <SelectLanguage props={{ mode, setMode }} /> */}
                            <TypographyTitleSidebar
                              props={{
                                text: menuExpanded
                                  ? t("company")
                                  : `${t("company")}`,
                                menuExpanded,
                              }}
                            />
                            <CompanyChoosing
                              props={{
                                textId: CompanyName,
                                chipText: t("company_choose_company"),
                              }}
                            />
                          </>
                        )}
                        {menuExpanded ? (
                          <></>
                        ) : (
                          <>
                            {BusinessId !== null ? (
                              <>
                                <TypographyTitleSidebar
                                  props={{
                                    text: menuExpanded
                                      ? t("business")
                                      : `${t("business")}`,
                                    menuExpanded,
                                  }}
                                />
                                <BusinessChoosing
                                  props={{
                                    textId: BusinessName,
                                    chipText: t("business_choose_business"),
                                  }}
                                />
                              </>
                            ) : null}
                            {/* <SelectLanguage props={{ mode, setMode }} /> */}
                          </>
                        )}
                        <TypographyTitleSidebar
                          props={{
                            text: menuExpanded
                              ? t("side_bar_menu")
                              : `${t("side_bar_menu")}`,
                            menuExpanded,
                          }}
                        />
                        {listItemMenu.map((menuItem, index) => (
                          <MenuItem
                            props={{
                              menuExpandedSub,
                              setMenuExpandedSub,
                              menuItem,
                              menuExpanded,
                              setDrawerBar,
                              expanded,
                              setExpanded,
                              setMenuExpanded,
                              drawerBar,
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={menuExpanded ? 11 : 8.5}
                  md={menuExpanded ? 11 : 8.5}
                  lg={menuExpanded ? 11.2 : 10}
                  sx={(theme) => ({
                    height: "100vh",
                    overflow: "auto",
                    position: "relative",
                  })}
                  onClick={() =>
                    window.innerWidth < 600 ? setMenuExpanded(true) : null
                  }
                  className="smoothTransition"
                >
                  <Box
                    sx={(theme) => ({
                      [theme.breakpoints.down("md")]: {
                        height: "100vh",
                      },
                      overflow: "auto",
                      height: "100vh",
                      boxSizing: "border-box",
                      backgroundColor: theme.palette.bgSizeBarRight.main,
                      position: "relative",
                    })}
                  >
                    <Outlet />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
export default Sidebar;
