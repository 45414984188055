import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllBusinessAPI } from "../../service/business/businessAPI";
import { useTranslation } from "react-i18next";
import { SetCookie } from "../../service/cookies/setCookies";
import CardItem from "./cardItem";
import { Loading } from "../../components/ui/loading/loading";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CompanyId } from "../../service/localStorage/localStorageService";
import BreadcrumbsTitle from "../../components/ui/breadcrumbs/breadcrumbsTitle";
import Cookies from "js-cookie";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/title/titleHeaderForm";
import { Add } from "@mui/icons-material";

function Business() {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onBusiness = async (itemData) => {
    await SetCookie(
      "business_uid",
      itemData.business_uid,
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    await SetCookie(
      "business_name",
      itemData.business_lang.business_name,
      7,
      process.env.REACT_APP_COOKIES_DOMAIN_URL
    );
    window.location.replace(
      `/company/${CompanyId}/business/${itemData.business_uid}/service`
    );
  };
  const onEdit = (uid) => {
    SetCookie("business_uid", uid, 7, process.env.REACT_APP_COOKIES_DOMAIN_URL);
    window.location.replace(`/company/${CompanyId}/business/detail/` + uid);
  };
  const fetchData = async () => {
    Cookies.remove("business_uid");

    let dataAPI = await getAllBusinessAPI();
    setData(dataAPI);
    setIsLoadingData(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <BreadcrumbsTitle
        props={{
          title: t("business"),
          dataLink: [
            { text: t("company"), link: "/company" },
            { text: t("business"), link: null },
          ],
        }}
      />
      <Box sx={{ p: 4, boxSizing: "border-box" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.primaryText.main,
              })}
              variant="h2"
            >
              {t("business")}
            </Typography>
            <Typography
              sx={(theme) => ({
                color: theme.palette.secondaryText.main,
              })}
              variant="h4"
            >
              {t("business_title_info")}
            </Typography>
          </Box>
          <Button
            startIcon={<Add />}
            variant="contained"
            sx={{ borderRadius: 2 }}
            onClick={() => navigate("create")}
          >
            Create
          </Button>
        </Box>
        <PaperWrapper props={{ growFull: 1 }}>
          <BoxFormTitleAction>
            <TitleHeaderForm props={{ text: t("business_title") }} />
          </BoxFormTitleAction>
          <BoxGapInput>
            {isLoadingData ? (
              <Loading />
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    p: 1,
                    boxSizing: "border-box",
                    flexWrap: "wrap",
                  }}
                >
                  {data.length > 0 ? (
                    <>
                      {data.map((business, index) => (
                        <>
                          <CardItem
                            props={{
                              isLoading: isLoadingData,
                              imgUrl: business.logo,
                              title: business.business_lang.business_name,
                              subTitle: business.type,
                              description: moment(business.updated_at).format(
                                "DD/MM/YYYY"
                              ),
                              data: business,
                              onClickDirect: onBusiness,
                              dataEdit: business.business_uid,
                              onClickEdit: onEdit,
                              bgColor: "#ffffff",
                              onClickDirectText: t("global_view_service"),
                            }}
                          />
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.secondaryText.main,
                        })}
                      >
                        {t("business_empty")}
                      </Typography>
                    </>
                  )}
                </Box>
              </>
            )}
          </BoxGapInput>
        </PaperWrapper>
      </Box>
    </>
  );
}

export default Business;
