import { Box, Chip, Divider, Typography } from "@mui/material";
import React from "react";
import { BoxImage } from "../../components/ui/boxImage/boxImage";
import EditIcon from "@mui/icons-material/Edit";
import { t } from "i18next";
import moment from "moment";
function CardItem({ props }) {
  let {
    title,
    imgUrl,
    data,
    onClickDirect,
    onClickEdit,
    dataEdit,
    isLoading,
    onClickDirectText,
    pair,
  } = props;
  return (
    <Box
      sx={(theme) => ({
        boxSizing: "border-box",
        backgroundColor: "#ffffff",
        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, .03)",
        borderRadius: "0.75rem",
        border: "1px solid #F1F1F1",
        position: "relative",
        width: "256px",
        flex: {
          xs: "0 0 100%",
          sm: "0 0 50%",
          md: "0 0 33.333333%",
          lg: "0 0 25%",
          xl: "0 0 20%",
        },
      })}
      className="transitionEaseInOut"
    >
      <Box
        sx={{
          boxSizing: "border-box",
          // p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <BoxImage
          image={imgUrl}
          isLoading={isLoading}
          height={"256px"}
          width={"100%"}
          type="table"
        />
        <Box sx={{ px: 2, boxSizing: "border-box" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                fontFamily: "Kanit",
                // textTransform: "uppercase",
                fontSize: 20,
                fontWeight: 500,
                width: "100%",
                whiteSpace: "pre-line",
                gap: 1,
              }}
            >
              {title}
              {pair ? (
                <>
                  <Chip label="Pair" />
                </>
              ) : (
                <></>
              )}
            </Typography>

            {/* <IconButton onClick={() => onClickEdit(dataEdit)}> */}
            <EditIcon
              sx={{
                opacity: 0.8,
                ":hover": { cursor: "pointer", opacity: 0.3 },
              }}
              color="primary"
              onClick={() => onClickEdit(dataEdit)}
            />
            {/* </IconButton> */}
          </Box>
          <Box sx={{ display: "flex", gap: 0.5, mt: 1 }}>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 400,
              }}
            >
              Business ID :
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                textTransform: "capitalize",
              })}
            >
              {data.business_uid}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 400,
              }}
            >
              Business type :
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                fontWeight: 400,
                textTransform: "capitalize",
              })}
            >
              {data.business_lang.business_type}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5, mt: 1 }}>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                color: theme.palette.primary.main + "50",
                fontWeight: 400,
              })}
            >
              Update at :
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                color: theme.palette.primary.main + "50",
                textTransform: "capitalize",
              })}
            >
              {moment(data.updated_at).format("DD/MM/YYYY")}
              {pair ? (
                <>
                  <Chip label="Pair" />
                </>
              ) : (
                <></>
              )}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}></Box>
        </Box>
      </Box>
      <Divider sx={{ my: 1, width: "100%" }} />
      <Box
        sx={(theme) => ({
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          // textTransform: "uppercase",
          // textDecoration: "underline",
          // textDecorationStyle: "dashed",
          color: theme.palette.info.main,
          pb: 1,
          ":hover": { opacity: 0.5, cursor: "pointer" },
        })}
        onClick={() => onClickDirect(data)}
        align="center"
      >
        {/* <DirectionsIcon /> */}
        <Typography
          sx={(theme) => ({
            fontSize: 14,
            borderBottom: `1px dashed ${theme.palette.info.main}`,
          })}
        >
          {onClickDirectText ? onClickDirectText : t("global_setting")}
        </Typography>
        {/* <East color="primary" sx={{ fontSize: 16 }} /> */}
      </Box>
    </Box>
  );
}
export default CardItem;
