import * as yup from "yup";
import { validationSchemas } from "./data";
export const businessSchema = yup.object().shape({
  businessName: validationSchemas.name,
  juristicName: validationSchemas.name,
  businessType: validationSchemas.name,
  taxId: validationSchemas.name,
  country: validationSchemas.name,
  city: validationSchemas.name,
  state: validationSchemas.name,
  postalCode: validationSchemas.name,
});
