import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PageNotFound from "../ui/page-not-found/pageNotFound";
import Login from "../../pages/auth/login";
import Company from "../../pages/company/company";
import CompanyCreate from "../../pages/company/companyCreate";
import CompanyDetail from "../../pages/company/companyDetail";
import Sidebar from "../sideBar/sideBar";
import BusinessCreate from "../../pages/business/businessCreate";
import BusinessDetail from "../../pages/business/businessDetail";
import ClientUserCreate from "../../pages/clientUser/clientUserCreate";
import ClientUserDetail from "../../pages/clientUser/clientUserDetail";
import { ListItemMenu } from "../../data/listItemMenu";
import { AccessTokenAPI } from "../../service/localStorage/localStorageService";
import ServiceBusiness from "../../pages/service-business/serviceBusiness";
import Register from "../../pages/register/register";
import React from "react";
import Business from "../../pages/business/business";

const AppRouter = () => {
  const listItemMenu = ListItemMenu();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/company" />} />
        <Route index path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* Client ================================= */}
        <Route path="company">
          <Route
            index
            element={
              <ProtectedRoute>
                <Company />
              </ProtectedRoute>
            }
          />
          <Route path="create" element={<CompanyCreate />} />
          <Route path="detail/:id" element={<CompanyDetail />} />
        </Route>
        <Route
          path="/"
          element={
            AccessTokenAPI ? <Sidebar /> : <Navigate to="/login" replace />
          }
        >
          <Route path="company/:companyId/">
            {listItemMenu.map((menu, index) => (
              <React.Fragment key={index}>
                <Route
                  key={menu.name}
                  path={menu.pathName}
                  exact={menu.exact}
                  element={<ProtectedRoute>{menu.component}</ProtectedRoute>}
                />
                {menu.subMenus && menu.subMenus.length > 0
                  ? menu.subMenus.map((subMenu, i) => (
                      <Route
                        key={subMenu.name}
                        path={subMenu.pathName}
                        element={
                          <ProtectedRoute>{subMenu.component}</ProtectedRoute>
                        }
                      />
                    ))
                  : null}
              </React.Fragment>
            ))}
            <Route
              path={`/company/:companyId/business`}
              element={
                <ProtectedRoute>
                  <Business />
                </ProtectedRoute>
              }
            />
            <Route
              path={`business/create`}
              element={
                <ProtectedRoute>
                  <BusinessCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path={`business/detail/:businessId`}
              element={
                <ProtectedRoute>
                  <BusinessDetail />
                </ProtectedRoute>
              }
            />
            <Route path="business/:businessId/">
              <Route
                path={`service`}
                element={
                  <ProtectedRoute>
                    <ServiceBusiness />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path={`clientUser/create`}
              element={
                <ProtectedRoute>
                  <ClientUserCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="clientUser/:id"
              element={
                <ProtectedRoute>
                  <ClientUserDetail />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
const ProtectedRoute = ({ children }) => {
  if (!AccessTokenAPI) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default AppRouter;
