import { t } from "i18next";
import * as yup from "yup";

function noWhitespace() {
  return this.transform((value, originalValue) =>
    /\s/.test(originalValue) ? NaN : value
  );
}

yup.addMethod(yup.number, "noWhitespace", noWhitespace);

const freeValidation = yup
  .string()
  .max(1000, t("validate_character_exceed_limitation_description"))
  .optional();
const commonValidation = yup
  .string()
  .required(t("validate_please_fill_out_information"));
const nameValidation = yup
  .string()
  .max(250, t("validate_character_exceed_limitation_name"))
  .min(2, t("validate_please_fill_out_information"))
  .required(t("validate_please_fill_out_information"));
const emailValidation = yup
  .string()
  .email(t("validate_please_fill_out_correct_format"))
  .matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    t("validate_please_fill_out_correct_format")
  )
  .required(t("validate_please_fill_out_information"));
const telValidation = yup
  .string()
  .matches(/^[0-9]{10}$/, t("validate_number_ten_digits"))
  .required(t("validate_please_fill_out_information"));
const numberValidation = yup
  .number()
  .noWhitespace()
  .min(0, t("validate_please_fill_out_more_than_zero"))
  .typeError(t("validate_please_fill_out_information_number"));

const numberIntegerValidation = yup
  .number()
  .integer(t("validate_please_fill_out_correct_format"))
  .noWhitespace()
  .min(0, t("validate_please_fill_out_more_than_zero"))
  .typeError(t("validate_please_fill_out_information_number"));
const numberSelectValidation = yup
  .number()
  .typeError(t("validate_please_select_information"));
const vatValidation = yup
  .number()
  .noWhitespace()
  .min(0, t("validate_please_fill_out_more_than_zero"))
  .lessThan(101, t("validate_please_fill_out_minimum_one_hundred"))
  .typeError(t("validate_please_fill_out_information_number"));
const percentValidation = yup
  .number()
  .noWhitespace()
  .min(0, t("validate_please_fill_out_more_than_zero"))
  .lessThan(101, t("validate_please_fill_out_minimum_one_hundred"))
  .typeError(t("validate_please_fill_out_information_number"));
const serviceChargeValidation = yup
  .number()
  .noWhitespace()
  .min(0, t("validate_please_fill_out_more_than_zero"))
  .lessThan(101, t("validate_please_fill_out_minimum_one_hundred"))
  .typeError(t("validate_please_fill_out_information_number"));
const pinValidation = yup
  .string()
  .matches(/^[0-9]{6}$/, t("validate_number_six_digits"))
  .required(t("validate_please_fill_out_information"))
  .typeError(t("validate_please_fill_out_information_number"));
const passwordValidation = yup
  .string()
  .min(8, "Password must be at least 8 characters")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?=.*[a-zA-Z\d@#$%^&+=!]).*$/,
    "ex: Strong123@, Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character. "
  )
  .required(t("validate_please_fill_out_information"));
const arrayValidation = yup
  .array()
  .of(yup.string().required(t("validate_please_select_information")))
  .typeError(t("validate_please_select_information"));
export const validationSchemas = {
  free: freeValidation,
  common: commonValidation,
  number: numberValidation,
  numberInteger: numberIntegerValidation,
  numberSelect: numberSelectValidation,
  email: emailValidation,
  name: nameValidation,
  percent: percentValidation,
  tel: telValidation,
  vat: vatValidation,
  serviceCharge: serviceChargeValidation,
  pin: pinValidation,
  password: passwordValidation,
  markSelect: arrayValidation,
};
