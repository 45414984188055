import { getCookie } from "../cookies/getCookies";

export const LanguageSystem = localStorage.getItem("language_system")
  ? localStorage.getItem("language_system").toLowerCase()
  : "";
export const LanguageForm = localStorage.getItem("language_form")
  ? localStorage.getItem("language_form").toUpperCase()
  : "";
export const AccessTokenAPI = getCookie("access_token")
  ? getCookie("access_token")
  : null;
export const CompanyId = getCookie("company_uid")
  ? getCookie("company_uid")
  : null;
export const CompanyName = getCookie("company_name")
  ? getCookie("company_name")
  : null;
export const BusinessId = getCookie("business_uid")
  ? getCookie("business_uid")
  : null;
export const BusinessName = getCookie("business_name")
  ? getCookie("business_name")
  : null;
export const StatusPair = getCookie("status_pair")
  ? getCookie("status_pair")
  : null;
