// RedPaper.js
import React from "react";
import Paper from "@mui/material/Paper";

const PaperWrapper = ({ children, props }) => {
  let { growFull, setting } = props;
  const paperStyle = {
    //stype
  };

  return (
    <Paper
      style={paperStyle}
      sx={{
        flexGrow: growFull === null ? growFull : 1,
        ...setting,
        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, .03)",
        borderRadius: "0.75rem",
        border: "1px solid #F1F1F1",
        backgroundColor: "#fff",
      }}
    >
      {children}
    </Paper>
  );
};

export default PaperWrapper;
