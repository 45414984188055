import React from "react";
// import TitlePage from "../../components/ui/title/titlePage";
import { Box, Grid } from "@mui/material";
import { t } from "i18next";
import ImageCrop from "../../components/ui/upload/imageCrop";
import AddressForm from "../../components/form/addressForm";
import { MapAddress } from "../../lib/map/map";
import TitleForm from "../../components/ui/title/titleForm";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import BusinessType from "./businessType.json";
import AutocompleteComponent from "../../components/ui/autocomplete/autoCompleteComponent";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import ConfirmButton from "../../components/ui/button/confirmButton";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/title/titleHeaderForm";
const vatStatusList = [
  { id: 1, name: t("business_vat_include") },
  { id: 2, name: t("business_vat_exclude") },
];
function BusinessForm({ props }) {
  let {
    // title,
    handleSubmit,
    onConfirm,
    setImage,
    image,
    register,
    errors,
    setAnchorMap,
    anchorMap,
    country,
    setCountry,
    isClickable,
    control,
    setValue,
    clearErrors,
  } = props;

  return (
    <Box>
      {/* <TitlePage props={{ text: title }} /> */}
      <GridContainerSize>
        <form onSubmit={handleSubmit(onConfirm)}>
          <BoxGapInput>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("Business") }} />
                </BoxFormTitleAction>
                <BoxGapInput>
                  <BoxWrapper>
                    <PaperWrapper props={{ growFull: null }}>
                      <TitleForm props={{ text: t("global_image") }} />
                      <ImageCrop
                        props={{
                          setImage,
                          image,
                          heightCrop: 256,
                          widthCrop: 256,
                        }}
                      />
                    </PaperWrapper>
                    <PaperWrapper props={{ growFull: 1 }}>
                      <TitleForm props={{ text: t("business_info") }} />
                      <BoxGapInput>
                        <TextfieldComponent
                          title={t("business_name")}
                          name="businessName"
                          register={register}
                          placeholder={t(
                            "validate_format_please_fill_out_form"
                          )}
                          errors={errors}
                        />
                        <TextfieldComponent
                          title={t("business_juristic_name")}
                          name="juristicName"
                          register={register}
                          placeholder={t(
                            "validate_format_please_fill_out_form"
                          )}
                          errors={errors}
                        />
                        {/* <TextfieldComponent
                  title={t("business_type")}
                  name="businessType"
                  register={register}
                  placeholder={t("validate_format_please_fill_out_form")}
                  errors={errors}
                /> */}

                        <AutocompleteComponent
                          title={t("business_type")}
                          name="businessType"
                          control={control}
                          setValue={setValue}
                          placeholder={t(
                            "validate_format_please_fill_out_form"
                          )}
                          errors={errors}
                          clearErrors={clearErrors}
                          optionsData={BusinessType.map(
                            (option) => option.name
                          )}
                          fullGrid={true}
                        />
                        <TextfieldComponent
                          title={t("business_tax_id")}
                          name="taxId"
                          register={register}
                          placeholder={t(
                            "validate_format_please_fill_out_form"
                          )}
                          errors={errors}
                        />

                        <AutocompleteComponent
                          title={t("vat.VatRegistration")}
                          name="vatStatus"
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          clearErrors={clearErrors}
                          placeholder={t(
                            "validate_format_please_fill_out_form"
                          )}
                          optionsData={vatStatusList.map(
                            (option) => option.name
                          )}
                          fullGrid={true}
                        />
                      </BoxGapInput>
                    </PaperWrapper>
                  </BoxWrapper>
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>

            <PaperWrapper props={{ growFull: 0 }}>
              <BoxFormTitleAction>
                <TitleHeaderForm props={{ text: t("address.addressInfo") }} />
              </BoxFormTitleAction>
              <BoxGapInput>
                <AddressForm
                  props={{
                    register,
                    errors,
                    setAnchorMap,
                    country,
                    setCountry,
                    control,
                    setValue,
                    clearErrors,
                  }}
                />
              </BoxGapInput>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 0 }}>
              <BoxFormTitleAction>
                <TitleHeaderForm props={{ text: t("global_map") }} />
              </BoxFormTitleAction>
              <MapAddress
                props={{ anchorMap, setAnchorMap, setCountry, setValue }}
              />
            </PaperWrapper>
            <Grid item xs={12}>
              <ConfirmButton disabled={isClickable} />
            </Grid>
          </BoxGapInput>
        </form>
      </GridContainerSize>
    </Box>
  );
}

export default BusinessForm;
