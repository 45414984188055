import axios from "axios";
import { AccessTokenAPI, CompanyId } from "../../localStorage/localStorageService";

export const RegisterSPOSService = async (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SMART_PLATFORM_API_URL}/api/spos/registerService?company_uid=${CompanyId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
