import Swal from "sweetalert2";

export const successSweetAlert = async (props) => {
  let { text } = props;
  Swal.fire({
    icon: "success",
    text: text,
    showConfirmButton: false,
    timer: 1500,
  });
};

export const errorSweetAlert = async (props) => {
  let { text } = props;
  Swal.fire({
    icon: "error",
    text: text,
    showConfirmButton: false,
    timer: 1500,
  });
};
