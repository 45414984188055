import axios from "axios";
import { BusinessId, CompanyId } from "../localStorage/localStorageService";
export const uploadImage = async (file) => {
  let FormData = require("form-data");
  let dataForm = new FormData();
  dataForm.append("image", file);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_UPLOAD_URL}/api/client/uploadImage?uploader=2&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=spos`,
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_API_UPLOAD_KEY_AUTH}`,
    },
    data: dataForm,
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data.data.key;
    })
    .catch((error) => {
      console.log(error);
    });
};
