import React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { roleList } from "../../data/roleList";
import { CompanyId } from "../../service/localStorage/localStorageService";
import { TypographyError } from "../../components/ui/typography/typography-error";
import RegisterApproval from "../register/registerApproval";
import SelectCheckMarkComponent from "../../components/ui/select/selectCheckMarkComponent";
import { t } from "i18next";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import TitleForm from "../../components/ui/title/titleForm";
import SelectComponent from "../../components/ui/select/selectComponent";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import ConfirmButton from "../../components/ui/button/confirmButton";
function ClientUserForm({ props }) {
  let {
    registerDone,
    handleSubmit,
    onConfirm,
    register,
    errors,
    // role,
    // onSelectRole,
    password,
    onClickShowPassword,
    setAnchorPassword,
    isClickable,
    anchorPassword,
    openAnchorPassword,
    businessList,
    clearErrors,
    control,
    setValue,
    clientBusinessSelect,
    setClientBusinessSelect,
    setClientBusinessIdSelect,
    detail,
  } = props;
  return (
    <div>
      <Box>
        {registerDone ? (
          <>
            <RegisterApproval
              buttonText={"back to user client user list"}
              linkUrl={`/company/${CompanyId}/clientUser`}
            />
          </>
        ) : (
          <>
            <GridContainerSize>
              <form onSubmit={handleSubmit(onConfirm)}>
                <BoxWrapper>
                  <PaperWrapper props={{ growFull: 1 }}>
                    <TitleForm props={{ text: t("client_user_info") }} />
                    <BoxGapInput>
                      <BoxWrapper>
                        <TextfieldComponent
                          title={t("register_first_name")}
                          name="firstname"
                          register={register}
                          placeholder={t(
                            "validate_format_please_fill_out_form"
                          )}
                          errors={errors}
                        />
                        <TextfieldComponent
                          title={t("register_last_name")}
                          name="lastname"
                          register={register}
                          placeholder={t(
                            "validate_format_please_fill_out_form"
                          )}
                          errors={errors}
                        />
                      </BoxWrapper>
                      <BoxWrapper>
                        <TextfieldComponent
                          title={t("register_email")}
                          name="email"
                          register={register}
                          placeholder={t(
                            "validate_format_please_fill_out_form"
                          )}
                          errors={errors}
                        />
                        <TextfieldComponent
                          title={t("register_tel")}
                          name="tel"
                          register={register}
                          placeholder={t(
                            "validate_format_please_fill_out_form"
                          )}
                          errors={errors}
                        />
                      </BoxWrapper>
                      <BoxWrapper>
                        {!detail ? (
                          <TextfieldComponent
                            title={t("register_username")}
                            name="username"
                            register={register}
                            placeholder={t(
                              "validate_format_please_fill_out_form"
                            )}
                            errors={errors}
                          />
                        ) : null}
                        {!detail ? (
                          <>
                            <SelectComponent
                              title={t("role")}
                              register={register}
                              name="role"
                              errors={errors}
                              control={control}
                              setValue={setValue}
                              clearErrors={clearErrors}
                              dataEmpty={t("Select Role")}
                              optionsData={roleList}
                              disabled={detail}
                            />
                          </>
                        ) : null}
                      </BoxWrapper>
                      <BoxWrapper>
                        {!detail ? (
                          <Box sx={{ width: "100%" }} mb={1}>
                            {/* <TitleTextField
                            props={{ text: t("register_password") }}
                          /> */}
                            <TextField
                              {...register("password")}
                              InputLabelProps={{ shrink: true }}
                              type={password.showPassword ? "text" : "password"}
                              label={t("register_password")}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{ gap: 1 }}
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        onClickShowPassword("showPassword")
                                      }
                                      edge="end"
                                    >
                                      {password.showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                    <IconButton
                                      onClick={(e) =>
                                        setAnchorPassword(e.currentTarget)
                                      }
                                    >
                                      <ErrorIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              name="password"
                              id="password"
                              placeholder="Password Ex: $Aa12345678"
                              size="small"
                              fullWidth
                            />
                            <TypographyError textError={errors?.password} />
                          </Box>
                        ) : null}
                        {!detail ? (
                          <Box sx={{ width: "100%" }} mb={1}>
                            {/* <TitleTextField
                            props={{
                              text: t("register_password_confirmation"),
                            }}
                          /> */}
                            <TextField
                              {...register("confirmPassword")}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              label={t("register_password_confirmation")}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        onClickShowPassword(
                                          "showConfirmPassword"
                                        )
                                      }
                                      edge="end"
                                    >
                                      {password.showConfirmPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              type={
                                password.showConfirmPassword
                                  ? "text"
                                  : "password"
                              }
                              name="confirmPassword"
                              id="confirmPassword"
                              placeholder="Confirm password"
                              size="small"
                            />
                            <TypographyError
                              textError={errors?.confirmPassword}
                            />
                          </Box>
                        ) : null}
                      </BoxWrapper>
                      {!detail ? (
                        <Box sx={{ width: "100%" }} mb={1}>
                          <SelectCheckMarkComponent
                            dataEmpty={t("client_business_select")}
                            errors={errors}
                            title={t("client_user")}
                            name="businessSelect"
                            dataOptions={businessList}
                            clearErrors={clearErrors}
                            control={control}
                            setValue={setValue}
                            valueData={clientBusinessSelect}
                            setValueData={setClientBusinessSelect}
                            setValueDataId={setClientBusinessIdSelect}
                          />
                          <TypographyError textError={errors?.businessSelect} />
                        </Box>
                      ) : null}

                      <ConfirmButton disabled={isClickable} />
                    </BoxGapInput>
                  </PaperWrapper>
                </BoxWrapper>
              </form>
            </GridContainerSize>
          </>
        )}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorPassword}
        open={openAnchorPassword}
        onClose={() => setAnchorPassword(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: "20%",
            px: 2,
          },
        }}
      >
        <Typography>{t("suggestions.passwordHint")}</Typography>
      </Menu>
    </div>
  );
}

export default ClientUserForm;
