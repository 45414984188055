import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { t } from "i18next";
import ImageCrop from "../../components/ui/upload/imageCrop";
import AddressForm from "../../components/form/addressForm";
import { MapAddress } from "../../lib/map/map";
import TitleForm from "../../components/ui/title/titleForm";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import ConfirmButton from "../../components/ui/button/confirmButton";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/title/titleHeaderForm";
function CompanyForm({ props }) {
  const navigate = useNavigate();
  const onBackCompany = async () => {
    navigate("/company");
  };
  let {
    title,
    handleSubmit,
    onConfirm,
    setImage,
    image,
    register,
    errors,
    setAnchorMap,
    anchorMap,
    country,
    setCountry,
    isClickable,
    control,
    setValue,
    clearErrors,
  } = props;
  return (
    <GridContainerSize>
      <Box
        sx={{
          height: "92vh",
          overflowY: "auto",
          p: 2,
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            maxWidth: "860px",
            width: "100%",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <IconButton onClick={() => onBackCompany()}>
              <ArrowBackIcon />
            </IconButton>
            <Typography
              sx={(theme) => ({
                fontSize: 18,
                color: theme.palette.secondaryText.main,
              })}
            >
              {title}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onConfirm)}>
            <BoxGapInput>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm
                      props={{ text: t("company_information") }}
                    />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <BoxWrapper>
                      <PaperWrapper props={{ growFull: null }}>
                        {/* <TitleForm props={{ text: t("global_image") }} /> */}
                        <TitleForm props={{ text: t("global_image") }} />
                        <ImageCrop
                          props={{
                            setImage,
                            image,
                            heightCrop: 256,
                            widthCrop: 256,
                          }}
                        />
                      </PaperWrapper>

                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm props={{ text: t("address_information") }} />

                        <BoxGapInput>
                          <TextfieldComponent
                            title={t("company_name")}
                            name="companyName"
                            register={register}
                            placeholder={t(
                              "validate_format_please_fill_out_form"
                            )}
                            errors={errors}
                          />
                          <AddressForm
                            props={{
                              register,
                              errors,
                              setAnchorMap,
                              country,
                              setCountry,
                              control,
                              setValue,
                              clearErrors,
                            }}
                          />
                        </BoxGapInput>
                      </PaperWrapper>
                    </BoxWrapper>
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>

              <PaperWrapper props={{ growFull: 0 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("global_map") }} />
                </BoxFormTitleAction>
                <BoxGapInput>
                  <MapAddress
                    props={{
                      anchorMap,
                      setAnchorMap,
                      setCountry,
                      setValue,
                    }}
                  />
                </BoxGapInput>
              </PaperWrapper>
              <ConfirmButton disabled={isClickable} />
            </BoxGapInput>
          </form>
        </Box>
      </Box>
    </GridContainerSize>
  );
}

export default CompanyForm;
