import React, { useState } from "react";
import {
  FormControl,
  Box,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  // Dialog,
  // DialogContent,
  Button,
  // DialogTitle,
  // IconButton,
  TextField,
  InputAdornment,
  Drawer,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { ArrowDropDown } from "@mui/icons-material";
import { isBrowser, isMobile } from "react-device-detect";
import { TypographyError } from "../typography/typography-error";

const SelectComponent = ({
  title,
  name,
  errors,
  dataEmpty,
  optionsData,
  onFunctionName,
  disabled,
  dataAlert,
  clearErrors,
  setValue,
  control,
  size,
  watch,
  // register,
}) => {
  // const [dialogOpen, setDialogOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const onChangeValue = (value) => {
    setValue(name, value);
    clearErrors(name);
  };
  const onOpenSelect = () => {
    // setDialogOpen(true);
  };
  const onSelectDialog = (item) => {
    setValue(name, item.id);
    // setDialogOpen(false);
  };
  const onDrawer = () => {
    setOpenDrawer(true);
  };

  const listDrawerSelectComponent = () => (
    <Box
      sx={{
        width: "auto",
        p: 2,
        boxSizing: "border-box",
        backgroundColor: "#f1f1f1",
      }}
      role="presentation"
      onClick={() => setOpenDrawer(false)}
    >
      <Typography sx={(theme) => ({ fontSize: 18, fontWeight: "500", mb: 2 })}>
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
          maxHeight: "30vh",
          overflow: "auto",
        }}
      >
        {optionsData.map((item, index) => (
          <Button
            variant="outlined"
            key={index}
            value={item.id}
            onClick={() => onSelectDialog(item)}
            fullWidth
          >
            {item.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
  return (
    <Box sx={{ width: "100%" }}>
      {/* <Box sx={{ mb: "0.5rem" }}>
        <TitleTextfield text={title} />
      </Box> */}
      {isBrowser ? (
        <>
          <FormControl fullWidth disabled={disabled}>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              {title}
            </InputLabel>
            <Controller
              control={control}
              name={name}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  {...field}
                  notched={true}
                  labelId="demo-simple-select-label"
                  error={errors[name]}
                  InputLabelProps={{ shrink: true }}
                  onBlur={() => {
                    clearErrors(name); // Clear errors when the field is blurred
                  }}
                  label={title}
                  onChange={(e) =>
                    onFunctionName
                      ? onFunctionName(e.target.value)
                      : onChangeValue(e.target.value)
                  }
                  onOpen={(e) => onOpenSelect()}
                  displayEmpty
                  size={size ? size : "small"}
                >
                  <MenuItem value={""} disabled>
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.secondaryText.main,
                        fontStyle: "italic",
                      })}
                    >
                      {dataEmpty}
                    </Typography>
                  </MenuItem>
                  {optionsData.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <TypographyError textError={errors[name]} />
        </>
      ) : null}

      {/* {isTablet ? (
        <>
          <TextField
            name={name}
            value={
              watch(name)
                ? optionsData.find((item) => item.id === watch(name)).name
                : null
            }
            type={"text"}
            error={errors[name]}
            fullWidth
            InputLabelProps={{ shrink: true }}
            placeholder={dataEmpty}
            onClick={(e) => onOpenSelect()}
            label={title}
            size="small"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <ArrowDropDown />
                  </InputAdornment>
                </>
              ),
            }}
          />
          <TextFormWithError textError={errors[name]} />
          {dataAlert}
          <Dialog open={dialogOpen} maxWidth="xs" fullWidth>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={(theme) => ({ fontSize: 18, fontWeight: "500", mb: 2 })}
              >
                {title}
              </Typography>
              <IconButton onClick={() => setDialogOpen(false)}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  width: "100%",
                  maxHeight: "30vh",
                  overflow: "auto",
                }}
              >
                {optionsData.map((item, index) => (
                  <Button
                    variant="outlined"
                    key={index}
                    value={item.id}
                    onClick={() => onSelectDialog(item)}
                    fullWidth
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            </DialogContent>
          </Dialog>
        </>
      ) : null} */}

      {isMobile ? (
        <>
          <TextField
            name={name}
            value={
              watch(name)
                ? optionsData.find((item) => item.id === watch(name)).name
                : null
            }
            type={"text"}
            error={errors[name]}
            fullWidth
            placeholder={dataEmpty}
            InputLabelProps={{ shrink: true }}
            onClick={(e) => onDrawer()}
            label={title}
            size="small"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <ArrowDropDown />
                  </InputAdornment>
                </>
              ),
            }}
          />
          <TypographyError textError={errors[name]} />
          {dataAlert}
          <Drawer
            anchor={"bottom"}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            {listDrawerSelectComponent()}
          </Drawer>
        </>
      ) : null}
    </Box>
  );
};

export default SelectComponent;
