import React from "react";
import StoreIcon from "@mui/icons-material/Store";
import Business from "../pages/business/business";
import { t } from "i18next";
export const ListItemService = () => {
  const listItem = [
    // ==== Business ====
    {
      name: t("side_bar_manage_service"),
      exact: true,
      iconClassName: <StoreIcon sx={{ fontSize: 20 }} />,
      role: "service",
      to: `/service`,
      pathName: "service",
      component: <Business />,
    },
  ];
  return listItem;
};
