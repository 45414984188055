import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import ServiceCard from "../serviceCard";
// import ServiceCardRegister from "../../register/serviceCardRegister";
import { t } from "i18next";
import { Add } from "@mui/icons-material";
function MemberShip({ props }) {
  let {
    data,
    onDirectToService,
    onRegisterService,
    onPairService,
    statusPair,
  } = props;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            justifyContent: "center",
          }}
        >
          <Typography
            sx={(theme) => ({
              color: theme.palette.primaryText.main,
            })}
            variant="h4"
          >
            {t("global_smart_membership_service")}
          </Typography>
        </Box>
        <Button
          startIcon={<Add />}
          variant="contained"
          sx={{ borderRadius: 2 }}
          // onClick={() => navigate("create")}
          onClick={() => onRegisterService()}
          disabled={data.length > 0}
        >
          Register
        </Button>
      </Box>
      {data.length !== 0 ? (
        <>
          <Grid container>
            {data.map((itemData, index) => (
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <ServiceCard
                  props={{
                    itemData,
                    onPairService,
                    onDirectToService,
                    statusPair,
                    data,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Typography
          sx={(theme) => ({
            color: theme.palette.secondaryText.main,
          })}
        >
          {t("global_smart_membership_empty")}
        </Typography>
      )}
    </Box>
  );
}

export default MemberShip;
