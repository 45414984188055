import { createContext, useEffect, useMemo, useState } from "react";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { Loading } from "../components/ui/loading/loading";
// import { getAllCompanyAPI } from "../service/company/companyAPI";
// import Swal from "sweetalert2";

const MyContext = createContext();

const MyProvider = ({ children }) => {
  const [state, setState] = useState(null);
  const [authRegister, setAuthRegister] = useState(null);
  // const [companyAll, setCompanyAll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDataInterface = async () => {
    // if (
    //   window.location.pathname !== "/login" &&
    //   window.location.pathname !== "/register"
    // ) {
    //   let dataAPI = await getAllCompanyAPI();
    //   if (dataAPI?.response?.status === 401) {
    //     Swal.fire({
    //       title: "Your session has expired.",
    //       icon: "warning",
    //       confirmButtonText: "BACK TO LOGIN",
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         window.location.replace("/login");
    //       }
    //     });
    //   } else {
    //     await setIsLoading(false);
    //   }
    // } else {
    //   await setIsLoading(false);
    // }
    // await fetchDataCompany();
    await setIsLoading(false);
  };
  // const fetchDataCompany = async () => {
  //   let dataAPI = await getAllCompanyAPI();
  //   setCompanyAll(dataAPI);
  // };
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ["Kanit"],
          h1: {
            fontSize: "clamp(2.5rem, 3vw, 4.5rem)",
          },
          h2: {
            fontSize: "clamp(1.5rem, 2vw, 3rem)",
          },
          h3: {
            fontSize: "clamp(1.25rem, 1.3vw, 2.5rem)",
          },
          h4: {
            fontSize: "clamp(1rem, 1vw, 2rem)",
          },
          h5: {
            fontSize: "clamp(0.7rem, 0.7vw, 1.2rem)",
          },
          body1: {
            fontSize: "clamp(0.85rem, 0.75vw, 1.325rem)",
          },
          titleTextField: {
            fontSize: "clamp(0.65rem, 0.9vw, 0.8rem)",
          },
        },
        palette: {
          primary: {
            main: "#1e2631",
          },
          secondary: {
            main: "#EE6925",
          },
          primaryText: {
            main: "#333333",
          },
          sidebarText: {
            main: "#7d819d",
          },
          info: {
            main: "#1B84FF",
          },
          secondaryText: {
            main: "#5c5c5c",
          },
          success: {
            main: "#39a751",
          },
          successBg: {
            main: "#e6fded",
          },
          error: {
            main: "#E53935E5",
          },
          bgSizeBarRight: {
            main: "#fefefe",
          },
          warning: {
            main: "#FFB300",
            contrastText: "black",
          },
          white: {
            main: "#ffffff",
          },
          notFound: {
            main: "#cccccc",
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1440,
            xl: 1920,
          },
          // values: {
          //   base: 0,
          //   mobileBase: 360,
          //   mobilePortrait: 480,
          //   mobileLandscape: 768,
          //   table: 1024,
          //   desktop: 1440,
          //   desktopFull: 1920,
          // },
        },
      }),
    []
  );
  theme.textError = {
    display: "block",
    color: "#dc3545",
    marginTop: "0.5rem 0rem",
    textAlign: "left",
  };
  theme.success = {
    display: "block",
    color: "#28A745",
    marginTop: "0.5rem",
    textAlign: "left",
    [theme.breakpoints.up("sm")]: { fontSize: "16px" },
    [theme.breakpoints.up("md")]: { fontSize: "16px" },
    [theme.breakpoints.up("lg")]: { fontSize: "16px" },
    [theme.breakpoints.up("xl")]: { fontSize: "16px" },
  };
  useEffect(() => {
    fetchDataInterface();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            height: "100vh",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              transform: "translate(-50%,-50%)",
              left: "50%",
              top: "50%",
            }}
          >
            <Loading />
          </Box>
        </Box>
      ) : (
        <>
          <MyContext.Provider
            value={{
              state,
              setState,
              authRegister,
              setAuthRegister,
              // companyAll,
            }}
          >
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
          </MyContext.Provider>
        </>
      )}
    </>
  );
};

export { MyContext, MyProvider };
